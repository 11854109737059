import styled from "styled-components";
import Colors from "../../styles/Colors";

export const ErrorMessageWrapper = styled.div`
    width: 100%;
    background-color: ${Colors.error};
    padding: 10px;


    p{
        color: white;
        font-size: 13px;
    }
`
