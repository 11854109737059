import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${(props) => props.background};
  margin-top: 20px;
  padding: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color: white;
  cursor: pointer;
  text-decoration: none;
  opacity: ${(props) => (props.onCart ? '0.6' : '1')};

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 400px) {
    flex-direction: column;
    padding: 15px;
  }
`;

export const LicenceName = styled.div`
  position: relative;

  &:after {
    content: '|';
    position: absolute;
    right: -20px;
    top: -10px;
    font-size: 30px;

    @media (max-width: 500px) {
      display: none;
    }
  }
`;

export const Value = styled.div`
  font-size: 20px;
  margin-left: 30px;

  @media (max-width: 400px) {
    margin-left: 0;
    margin-top: 15px;
  }
`;
