import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${Colors.grayDefault};
  padding: 15px 10%;
  display: flex;
  justify-content: center;
  align-items: center;

  main {
    margin: 0 auto;
    width: 75%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 850px) {
      flex-direction: column;
      align-items: center;
      width: 100%;
    }
  }
`;

export const EditButton = styled.button`
  position: absolute;
  top: -35px;
  cursor: pointer;
  background-color: ${Colors.redDefault};
  color: white;
  border: none;
  padding: 10px 50px;
  font-weight: bold;
  border-radius: 5px;
`;

export const Infos = styled.div`
  width: 100%;
  margin-top: 20px;

  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */

  h1 {
    font-size: 20px;
  }

  @media (max-width: 850px) {
    display: flex;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    align-items: center;
  }
`;

export const Description = styled.p`
  max-width: 60%;
  /* text-align: justify; */
  line-height: 26px;
  margin-top: 10px;

  @media (max-width: 850px) {
    margin-left: 0;
    max-width: 100%;
  }
`;

export const UserInformation = styled.div`
  p {
    margin-left: 10px;
  }

  a {
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &:last-child {
    margin-bottom: 0;
  }

  a:visited {
    color: inherit;
  }

  @media (max-width: 850px) {
    p {
      margin-left: 0;
    }

    a {
      flex-direction: column;
      gap: 10px;
    }
  }
`;

export const OtherInfo = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 850px) {
    margin: 0;
    margin-top: 30px;
    width: 100%;
    justify-content: center;
  }
`;

export const TechnicalInfo = styled.div`
  font-weight: regular;
  margin-top: 20px;
  // display: none;
  // display: flex;
  align-items: center;
  font-size: 13px;

  p{
    margin-bottom: 10px;
  }


  @media (max-width: 850px) {
    // display: none;
    text-align: center;
  }
`;

export const BackLink = styled.p`
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  margin-top: 10px;
  color: ${Colors.fontDefault};
  text-align: right;

  @media (max-width: 850px) {
    display: none;
  }
`;

export const DateText = styled.p`
  font-size: 14px;
  text-align: right;
  margin-bottom: 30px;
`
