import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${Colors.BlackPhotoDisplay};
  display: flex;
  justify-content: center;
  padding: 10px 0;
  margin-top: 40px;

  img {
    max-height: calc(100vh - 190px);
    margin: 0 auto;

    @media (max-width: 500px) {
      max-width: calc(100% - 20px);
    }
  }

  @media (max-width: 850px){
    margin-top: 0;
  }
`;
