import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Content = styled.ul`
  margin-top: 70px;

  @media (max-width: 900px) {
    > li + li {
      margin-top: 80px;
    }
  }
`;

export const TotalBar = styled.div`
  text-align: right;
  padding: 30px 50px;
  background-color: ${Colors.blueDefault};

  p {
    text-transform: uppercase;
    font-weight: bold;
    color: white;
    font-size: 20px;
  }

  p + p {
    margin-top: 30px;
    border-top: 5px solid white;
    display: inline-block;
    padding-top: 30px;
    font-size: 25px;
  }

  @media (max-width: 900px) {
    margin-top: 120px;
    padding: 20px;
  }

  @media (max-width: 550px) {
    margin-top: 100px;
    padding: 20px;
  }
`;

export const ButtonWrapper = styled.div`
  text-align: right;
  padding: 0 50px;
  margin-top: 30px;
  margin-bottom: 60px;

  @media (max-width: 900px) {
    width: 100%;
    text-align: center;
    padding: 0 10px;

    > button {
      width: 100%;
    }

    > a + button {
      margin-top: 10px;
    }
  }
`;

export const NoItems = styled.p`
  margin-top: 70px;
  font-size: 18px;
`;
