import styled from 'styled-components';
import Colors from '../../styles/Colors';


export const ContactWrapper = styled.div`
  margin-top: 40px;

  p {
    text-align: justify;
    line-height: 26px;
    font-size: 15px;

    bold{
      font-weight: bold;
      color: ${Colors.fontDefault};
    }
  }
`;

export const TitleSection = styled.h2`
    text-align: justify;
    line-height: 26px;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Merriweather',serif;
    color: ${Colors.fontDefault};
    margin: 40px 0 20px;
`;

export const ButtonWrapper = styled.div`
  display: flex;

`

export const FormWrapper = styled.div`
    margin-top: 80px;
    display: flex;

    
    form{
        
        display: inline-block;
        width:60%;
        padding-right: 30px;
        
        textarea{
            min-height: 150px;
        }
        
        button{
            margin: 0 auto;
        }
    }
}

@media(max-width: 850px){
    flex-direction: column;

    form{
        width: 100%;
    }
}
`

export const SocialWrapper = styled.div`
    border-left: 3px solid ${Colors.redDefault};
    width: 39%;
    padding-left: 30px;
    padding-top: 30px;
    
    span{
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 100px;
        flex-wrap: wrap;
    }

    a{
        color: ${Colors.fontDarker}
        text-decoration: none;
    }
    
    a:visited{
        color: inherit;
    }


    @media(max-width: 850px){
        width: 100%;
        padding: 0;
        border: 0;
        margin-top: 50px;
        margin-bottom: 50px;

        span{
            justify-content:initial;
            margin-top: 0;
            margin-bottom: 30px;

            div + div{
                margin-left: 30px;
            }
        }
        
    }
`

export const Social = styled.div`
    margin-top:30px;


    a{
        display: flex;
        align-items: center;
        color: ${Colors.fontDefault}
    }

    a:visited{
        color: ${Colors.fontDefault};
    }

    img{
        width: 25px;
        margin-right: 10px;
    }

`
export const SentMessage = styled.p`
    padding: 20px;
    display: block;
    background-color: ${(input) => input.error ? Colors.error : Colors.success};
    color:white;
    margin-top: 20px;
`