import styled from 'styled-components';
import Colors from '../../styles/Colors';
import pointerNotifications from '../../assets/pointerNotifications.svg';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;

  /* &:hover > div {
    display: block;
  } */

  > div::before {
    content: '';
    display: block;
    background: url(${pointerNotifications});
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 100%;
    position: absolute;
    top: -6px;
    left: 70px;
  }

  > div {
    display: block;
    position: absolute;
    min-width: 400px;
    top: 40px;
    padding-top: 10px;
    left: -246px;
    color: ${Colors.fontDefault};
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    border: 3px solid white;
    border-radius: 10px;
  }

  ul {
    border-radius: 5px;
    background-color: ${Colors.goldDefault};
  }

  li {
    list-style-type: none;
    padding: 23px 0;
    cursor: pointer;
    font-weight: 500;

    &:hover {
      opacity: 0.6;
    }
  }

`;

export const Btn = styled.button`
  height: 40px;
  width: 45px;
  border: none;
  background-color: ${(props) =>
    props.notification ? Colors.redDefault : Colors.goldDefault};
  cursor: pointer;
  color: ${(props) => (props.notification ? 'white' : Colors.fontDefault)};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  outline: none;

  svg {
    color: ${(props) => (props.notification ? 'white' : Colors.fontDefault)};
  }

  svg + p {
    margin-left: 4px;
  }

  &:hover {
    opacity: 0.6;
  }

  &hover > ul {
    display: block;
  }
`;
