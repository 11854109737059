import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;

  @media (max-width: 1000px) {
    justify-content: center;
  }
`;

export const ImageContainer = styled.div`
  width: 50%;

  @media (max-width: 1000px) {
    display: none;
  }
`;
export const Main = styled.main`
  width: 50%;
  padding: 50px 200px;
  box-sizing: border-box;

  @media (max-width: 1430px) {
    padding: 200px 50px;
  }

  @media (max-width: 1000px) {
    padding: 50px;
    width: 80%;
  }

  @media (max-width: 500px) {
    padding: 50px 20px;
    width: 100%;
  }
`;
