import styled from 'styled-components';
import Colors from '../../styles/Colors';
import PointerMore from '../../assets/pointerMore.svg';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;

  @media (max-width: 850px) {
    display: none;
  }

  &:hover > div {
    display: block;
  }

  div::before {
    content: '';
    display: block;
    background: url(${PointerMore});
    background-repeat: no-repeat;
    background-position: center;
    height: 20px;
    width: 100%;
    position: absolute;
    top: -6px;
    left: -3px;
  }

  div {
    display: none;
    position: absolute;
    min-width: 200px;
    top: 40px;
    padding-top: 10px;
    left: -80px;
    color: white;
    text-align: center;
    font-size: 13px;
    text-transform: uppercase;
    /* border: 3px solid white; */
    border-radius: 10px;
  }

  ul {
    border-radius: 5px;
    background-color: ${Colors.MoreBackground};
    padding: 30px 0;
    
    span {
      display: block;
      width: 80%;
      border-top: 2px solid white;
      margin: 15px auto 0;

      a, li{
        text-decoration: none;
        font-family: 'Montserrat', sans-serif;
        
      }

    }

    li,
    a {
      display: block;
      list-style-type: none;
      padding: 10px 20px;
      cursor: pointer;
      font-weight: 500;
      color: white;

      &:hover {
        opacity: 0.6;
      }

      a:visited {
        color: white;
      }
    }
  }
`;

export const Btn = styled.button`
  height: 40px;
  border: none;
  background-color: white;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:hover {
    opacity: 0.6;
  }

  &hover > ul {
    display: block;
  }
`;
