import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  padding: 8px 20px;
  font-size: 13px;
  color: ${Colors.fontDefault};
  background-color: ${Colors.grayDefault};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-family: 'Montserrat', sans-serif;
  gap: 10px;

  svg {
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
`;
