import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import { Helmet } from 'react-helmet';
import Colors from '../../styles/Colors';
import Button from '../../components/Button';
import { useHistory } from 'react-router';
import Footer from '../../components/Footer';

const NotFoundWrapper = styled.div`
margin-top: 40px;
display: flex;
flex-direction: column;
align-items: center;

p {
  text-align: center;
  line-height: 26px;
  font-size: 15px;
  margin-top: 50px;
  
  bold{
    font-weight: bold;
    color: ${Colors.fontDefault};
  }
}

button{
  margin-top: 80px;
}

`;

const TitleSection = styled.h2`
text-align: justify;
font-size: 200px;
text-transform: uppercase;
font-family: 'Montserrat',sans-serif;
color: ${Colors.fontDefault};


@media (max-width: 600px){
  font-size: 150px;
}
`;

export default function NotFound() {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Termos de Uso | ViAssim</title>
      </Helmet>
      <Header />
      <BaseMain>
        <NotFoundWrapper>
          <TitleSection>404</TitleSection>

          <p>Não encontramos a página que você está buscando. Mas fique a vontade para navegar e se inspirar com os trabalhos incríveis dos fotógrafos.</p>

          <Button
                  backgroundColor={Colors.goldDefault}
                  text='Voltar para home'
                  borderWidth={0}
                  onClick={() => {history.push('/')}}
                />

        </NotFoundWrapper>
      </BaseMain>
      <Footer />
    </>
  );
}
