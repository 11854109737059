import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 60%;
  background-color: ${Colors.goldDefault};
  margin: 0 auto 100px;
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-radius: 10px;

  > div:nth-child(1) {
    width: 60px;
    height: 60px;
  }

  @media (max-width: 850px) {
    width: 100%;
    margin-bottom: 0;
    border-radius: 0;

    > div:nth-child(1) {
      width: 35px;
      height: 35px;
    }
  }
`;

export const CommentInput = styled.textarea`
  margin-left: 20px;
  width: calc(100% - 180px);
  border: none;
  resize: none;
  border-radius: 10px;
  align-items: center;
  padding: 15px 10px;

  @media (max-width: 850px) {
    width: 100%;
  }
`;

export const SubmitComment = styled.div`
  width: 50px;
  text-align: center;
  cursor: pointer;
  margin-left: 15px;

  svg {
    color: white;
  }

  @media (max-width: 850px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`;

export const LoadingContainer = styled.div`
  width: 70%;
  margin: 0 auto;
  text-align: center;
`