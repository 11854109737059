import styled from 'styled-components';

export const BaseMain = styled.main`
  width: 70%;
  margin: 0 auto;
  padding: 90px 0 20px;
  min-height: calc(100vh - 388px);
  margin-bottom:30px;

  @media(max-width: 850px){
    padding: 40px 0 20px;
}

  @media (max-width: 550px) {
    width: 90%;
  }
`;
