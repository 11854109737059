import styled from 'styled-components'
import Colors from '../../styles/Colors'

export const WrapperNeedRegisterToSell = styled.div`

    background-color: ${Colors.blueDefault};
    padding: 30px;
    color: white;
    font-size: 15px;
    text-align: justify;
    line-height: 26px;
    font-weight: 600;

    p + p{
        margin-top: 26px;
    }

`

export const ButtonToGetAuthorization = styled.p`
    cursor: pointer;
    text-decoration: underline;

    &:hover{
        opacity: 0.8;
    }
`