import styled from 'styled-components';
import Colors from '../../styles/Colors';

const TagBar = styled.div`
  width: 100%;
  padding: 15px 25%;
  background-color: ${Colors.blueDefault};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

  > a + a {
    margin-left: 20px;
  }

  a {
    margin-bottom: 10px;
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 850px) {
    padding: 15px;
  }
`;

export default TagBar;
