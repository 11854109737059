import styled from 'styled-components';

export const BtnWrapper = styled.span`
  margin: 0 auto;
  display: flex;
  gap: 20px;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 80px;

  @media (max-width: 650px) {
    flex-direction: column;
    text-align: center;
  }
`;
