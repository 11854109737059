import styled from "styled-components";
import Colors from "../../styles/Colors";


export const WrapperTransactions = styled.div`
    text-align: center;
`

export const WrapperItens = styled.div`
    margin-top: 70px;
`

export const Instructions = styled.div`
  background-color: ${Colors.goldDefault};
  margin-top: 20px;
  padding: 30px;

  > p{
    line-height: 30px;
  }

`;