const Colors = {
  redDefault: '#951919',
  blueDefault: '#67B9BB',
  grayDefault: '#F0F0F0',
  goldDefault: '#ECCFB1',
  fontDefault: '#585858',
  fontDarker: '#3E3C3C',
  MoreBackground: '#5F5F5F',
  BlackPhotoDisplay: '#181717',
  lighterGray: '#656565',
  personalLicence: '#94A4B7',
  commercialLicence: '#44B5E4',
  merchandising: '#FBB53C',
  downloadBtn: '#D3B08B',
  downloadedBtn: '#86715B',
  error: '#C91212',
  success: '#158026',
  darkerGold: '#D7B694'
};

export default Colors;
