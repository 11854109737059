import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  /* height: 200px; */
  padding: 10px 100px;
  border-bottom: 3px solid ${Colors.goldDefault};
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  position: --webkit-sticky;
  top: 0;
  z-index: 3;
  background-color: white;

  @media (max-width: 1000px) {
    padding: 40px;
  }
`;

export const LogoSearchWrapper = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 850px) {
    flex-direction: column;
    gap: 30px;
    width: 100%;
  }

  img {
    width: 30%;
    margin-right: 70px;

    @media (max-width: 1200px) {
      margin-right: 30px;
    }

    @media (max-width: 850px) {
      margin-right: 0;
      width: 50px;
    }
  }
`;

export const UserAccessWrapper = styled.div`
  display: flex;
`;

export const UploadBtn = styled.button`
  position: absolute;
  right: 50%;
  transform: translateX(50%);
  padding: 8px 40px;
  box-sizing: border-box;
  background-color: ${Colors.redDefault};
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;

  &:hover {
    opacity: 0.6;
  }

  @media (max-width: 1400px) {
    position: initial;
    margin-right: 30px;
    transform: none;
  }

  @media (max-width: 1130px) {
    padding: 0 15px;

    & > p {
      display: none;
    }
  }

  @media (max-width: 850px) {
    position: absolute;
    left: 40px;
    top: 40px;
    margin-right: 0;
    right: initial;
    padding: 5px 10px;
  }
`;

export const MobileMenuWrapper = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  /* overflow-x: hidden; */

  > span {
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
  }

  > nav {
    position: absolute;
    width: ${({ open }) => (open ? '300px' : '0')};
    top: -40px;
    right: -40px;
    height: 100vh;
    background-color: ${Colors.fontDefault};
    overflow-y: scroll;
    font-family: 'Montserrat', sans-serif;
    color: white;
    font-weight: bold;
    transition: width 320ms ease;

    div {
      margin-top: 15px;
      height: 80px;
      background-color: ${Colors.goldDefault};
      margin-bottom: 50px;

      svg {
        position: absolute;
        top: 40px;
        right: 40px;
        color: ${Colors.fontDefault};
        cursor: pointer;
      }
    }

    ul {
      padding: 30px;
    }

    li {
      text-transform: uppercase;
      display: block;
      cursor: pointer;

      a {
        text-decoration: none;
        color: inherit;
      }
    }

    li + li {
      margin-top: 30px;
    }
  }

  li:nth-last-child(4) {
    border-top: 2px solid white;
    padding-top: 45px;
  }

  @media (min-width: 850px) {
    display: none;
  }
`;

export const Cart = styled.div`
  display: flex;
  position: absolute;
  right: 100px;
  top: 45px;

  @media (max-width: 400px) {
    right: 85px;
  }

  a {
    color: initial;
    text-decoration: none;
    font-family: 'Montserrat', sans-serif;
    display: flex;
    > svg + p {
      margin-left: 5px;
    }
  }

  svg {
    color: ${Colors.fontDefault};
  }

  @media (min-width: 850px) {
    display: none;
  }
`;
