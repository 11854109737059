import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  font-family: 'Montserrat', sans-serif;
  margin-bottom: 40px;
  position: relative;

  // > a{
  //   cursor: pointer;
  //   display: block;
  // }

`;

export const ContentWrapper = styled.div`
  margin-left: 20px;
  width: calc(100% - 80px);
`;

export const DateElement = styled.p`
  font-size: 10px;
  color: ${Colors.lighterGray};
  margin-left: 10px;
  font-weight: bold;
`;

export const CommentHeader = styled.div`
  display: flex;
  align-items: center;

  a {
    text-decoration: none;
    color: ${Colors.fontDefault};
  }
`;

export const UserComment = styled.p`
  margin: 15px 0;
  line-height: 26px;

  @media (max-width: 850px) {
    font-size: 14px;
  }
`;

export const ReplyButton = styled.p`
  font-size: 10px;
  color: ${Colors.lighterGray};
  font-weight: bold;
  cursor: pointer;
`;

export const ReplyContainer = styled.div`
  width: 90%;
  margin-left: auto;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  margin-bottom: 30px;
`;

export const Reply = styled.div`
  padding: 10px 0;
  display: flex;
  position: relative;
`;

export const WriteReply = styled.div`
  > div {
    width: 90%;
    margin: -15px auto 30px;

    input {
      width: 90%;
    }

    div:nth-child(3) {
      width: 50px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 10px;
    }
  }
`;


export const DeleteButton = styled.div`
  position: absolute;
  top:0;
  right: 0;
  cursor: pointer;
`

export const CommentMenu = styled.div`
  position: absolute;
  right: -60px;
  top: 30px;

  p{
    font-size: 13px;
    text-align: center;
    margin-bottom: 10px;
    cursor:pointer;
  }
`

export const SpinnerContainer = styled.div`
    width: 50px;
    height: 50px;
`