import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import Title from '../../components/Title';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';

const QuemSomosWrapper = styled.div`
  margin-top: 70px;

  p {
    text-align: justify;
    line-height: 26px;
  }
`;

export default function QuemSomos() {
  return (
    <>
      <Helmet>
        <title>Sobre nós | ViAssim</title>
      </Helmet>
      <Header />
      <BaseMain>
        <Title text='Sobre nós' />
        <QuemSomosWrapper>
          <p>
          O ViAssim é um marketplace de fotografia, que tem como objetivo desburocratizar o mercado de fotografia de forma simples e descomplicada. Conectamos o fotógrafo, que deseja vender sua fotografia de forma profissional, organizada e com o seu devido valor, com os que desejam comprar fotografia. 
          </p>
          <br></br>
          <p>
          O fotógrafo coloca seu acervo para vender em nossa plataforma, transforma seu espaço virtual numa grande galeria e exposição de arte, e ele mesmo coloca o real preço da sua peça fotográfica. 
          </p>
          <br></br>
          <p>
          Quem aprecia fotografia, a arte, ou deseja fazer uso para publicidade, editorial, design de interiores, ou para sua coleção comprará a imagem com os devidos direitos autorais, pagará com o cartão, e o fotógrafo receberá direto por isso. Simples assim.
          </p>
          <br></br>
          <p>
          Entendemos que cada imagem possui um custo envolvido do profissional de fotografia, seus direitos autorais, produção e equipamentos necessários. Por trás de um clique, existe um profissionalismo que precisa ser respeitado e valorizado. 
          </p>
          <br></br>
          <p>
          ViAssim respeita você, a arte, os valores intelectuais e o profissional de fotografia. 
          </p>
          <br></br>
          <p>
          Sustentabilidade é respeitar o trabalho do outro, mesmo que ele seja intangível.
          </p>
          <br></br>
          <p>
          Assim, nasce a ViAssim. 
          </p>
        </QuemSomosWrapper>
      </BaseMain>
      <Footer />
    </>
  );
}
    