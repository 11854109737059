import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.section`
  width: 100%;
  margin-bottom: 20px;
  color: ${Colors.fontDefault};
  border-bottom: 6px solid ${Colors.blueDefault};
  position: relative;

`;

export const SectionHeader = styled.p`
  display: block;
  padding: 15px 0;
  background-color: ${Colors.blueDefault};
  color: white;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 30px;
`;

export const LicencesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  max-width: 600px;

  > p + p {
    width: 100%;
    text-align: center;
    margin-top: -30px;
    font-size: 14px;
    margin-bottom: 20px;
  }
  /* 
  input {
    margin-bottom: 0;
    width: 200px;
  } */

  p:first-child {
    margin-bottom: 40px;
  }

  @media (max-width: 850px) {
    width: 90%;

    > div + div {
      margin-top: 30px;
    }
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  width: 100%;
`;

export const InfoHeader = styled.div`
  text-align: center;

  p:last-child {
    font-size: 14px;
    margin-top: 8px;
  }
`;

export const ChooseLicence = styled.div`
  display: flex;
  margin-top: 20px;
  width: 90%;
  align-items: center;
  // border-bottom: 5px solid
    // ${(props) => (props.selected ? props.bgColor : 'white')};
  padding: 10px;
  position: relative;

  span {
    display: flex;
    align-items: center;

    p {
      font-weight: ${(props) => (props.selected ? 'bold' : 'regular')};
      margin-left: 10px;
    }
  }
  input {
    border: none;
    width: 150px;
    padding: 10px 5px;
    /* position: absolute; */
    font-size: 20px;
    text-align: right;
    margin-left: auto;

    &::placeholder {
      font-size: 14px;
    }

    &:focus {
      outline: none;
    }
  }

  @media (max-width: 800px) {
    flex-direction: column;
    align-items: initial;

    input {
      margin-left: 0;
      width: 100%;
      text-align: center;
    }

    > span > p {
      margin-left: ${(props) => (props.selected ? 'auto' : 10)};
    }
  }
`;

export const InfoWrapper = styled.div`
  width: 50%;
  padding: 0 80px;
`;

export const Format = styled.div`
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 55px;

  div {
    width: 50%;
  }

  label {
    display: block;
    text-align: left;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
  }

  input {
    display: block;
    border: none;
    border-bottom: 2px solid ${Colors.goldDefault};
    font-size: 16px;
    text-align: left;
    margin-bottom: 60px;
    width: 100%;

    &:focus {
      outline: none;
    }
  }
`;

export const More = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${Colors.fontDefault};
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0 auto;
  position: relative;

  > p {
    margin-top: 60px;
  }

  svg {
    cursor: pointer;
    margin-top: 10px;
  }
`;

export const MinimalValueMessage = styled.div`
  text-align: center;
  padding: 10px;
  background-color: ${Colors.error};
  color: white;
  font-size: 14px;
`

export const MinimalValue = styled.div`
  margin-top: -10px;
  p{
    font-size: 14px;
    padding: 10px;
    border-bottom: 1px solid ${Colors.fontDarker}
  }
`

export const WrapperAuthorization = styled.div`
  color: ${Colors.fontDarker};  
  p{
    line-height: 26px;
    margin-top: 10px;
  }

  bold{
    font-weight: bold;
  }

  h3{
      text-transform: uppercase;
      font-weight: bold;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 30px;
  }

  div{
    margin-top: 30px;
    padding-bottom: 30px;
    label + input{
      margin-left: 20px;
    }

  }

  span{
    display: block;
    padding-bottom: 50px;
  }

`

export const ErrorMessage = styled.p`
  font-size: 14px;
  color: ${Colors.error};
`