import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Header = styled.header`
  text-align: center;
  margin-bottom: 70px;
`;

export const Wrapper = styled.div`
  padding-top: 50px;
  width: 50%;
  margin: 0 auto;

  @media (max-width: 1100px) {
    width: 90%;
    padding: 50px 20px;
  }
`;

export const Main = styled.main`
  width: 100%;
  margin-top: 100px;

  section {
    width: 150px;
    height: 150px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin: 0 auto;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
`;

export const AvatarSelect = styled.p`
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: ${Colors.fontDefault};
`;

export const AvatarUpload = styled.div`
  text-align: center;
  border: 1px dashed black;
  height: 100px;
  margin-top: 20px;
  margin-bottom: 80px;

  div {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;

export const Form = styled.form`
  margin-bottom: 70px;

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;

    span {
      display: flex;
      flex-direction: column;
    }

    @media (max-width: 800px) {
      flex-direction: column;
    }
  }

  textarea {
    resize: none;
    line-height: 26px;
  }

  & > p {
    font-size: 18px;
    color: ${Colors.fontDefault};
    font-weight: bold;
  }
`;

export const FieldCheckbox = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  div {
    width: 30%;

    p {
      margin-right: auto;
      margin-left: 10px;
      font-size: 16px;
    }
  }

  @media (max-width: 800px) {
    flex-direction: initial;
    gap: 50px;
  }

  @media (max-width: 500px) {
    flex-direction: initial;
    gap: 30px;

    div {
      width: 40%;
    }
  }

  @media (max-width: 450px) {
    flex-direction: initial;
    gap: 30px;

    div {
      width: 50%;
    }
  }
`;

export const TypeCheckbox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 50px;
  gap: 30px;

  p {
    margin-right: auto;
    margin-left: 10px;
    font-size: 16px;
  }
`;
