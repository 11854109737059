import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 80px;

  img {
    width: 100px;
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const ForgotPassword = styled.div`
  margin-top: -30px;
  font-size: 14px;
  margin-bottom: 100px;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;

  button + a {
    margin-left: 10px;
  }
`;

export const ReturnBtn = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;

  a {
    color: ${Colors.fontDefault};
    text-decoration: none;
  }

  a:visited {
    color: ${Colors.fontDefault};
  }
`;

export const Response = styled.p`
  line-height: 26px;
  /* text-align: justify; */
  margin-bottom: 80px;
`;

export const ResponseWrapper = styled.div`
  text-align: center;
`;
