import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const CommentsWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 0 10px;
  margin-top: 60px;

  @media (max-width: 850px) {
    width: 80%;
  }

  @media (max-width: 550px) {
    width: 95%;
  }
`;

export const More = styled.div`
  text-align: center;
  font-size: 14px;
  color: ${Colors.fontDefault};
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  padding-bottom: 20px;
  margin: 0 auto;
  position: relative;

  svg {
    cursor: pointer;
    margin-top: 10px;
  }

  @media (max-width: 900px) {
    margin-top: 20px;
  }
`;
