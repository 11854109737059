import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 32%;
  min-height: 400px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  border: 2px solid ${Colors.goldDefault};
  padding: 40px 20px;
  position: relative;

  a {
    text-decoration: none;
    color: initial;
  }

  @media (max-width: 1600px) {
    width: 48%;
  }

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;

  > :first-child {
    margin-right: 10px;
  }
`;

export const User = styled.p`
  font-size: 15px;
  margin-top: 10px;
`;

export const Photos = styled.div`
  display: flex;
  margin-top: 20px;

  > div {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;

    > p {
      margin-top: 30px;
      width: 100%;
      text-align: center;
    }
  }

  a {
    max-height: 200px;
    width: 92%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    overflow: hidden;

    img + img {
      margin-right: 0;
    }
  }

  img {
    height: 130%;
  }

  > :last-child {
    margin-right: 0;
  }
`;

export const ViewProfile = styled.button`
  display: block;
  position: absolute;
  bottom: 15px;
  width: calc(100% - 40px);
  margin-top: 15px;
  border: none;
  background-color: ${Colors.goldDefault};
  padding: 15px 0;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;

  &:hover {
    opacity: 0.7;
  }
`;

export const UserInfo = styled.div``;
