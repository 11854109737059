import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const PhotoContainer = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  position: relative;

  img {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }

  a {
    display: block;
    justify-content: end;
    position: absolute;
    bottom: -60px;
    width: 100%;
    padding: 15px 0;
    background-color: ${(props) =>
      props.downloaded ? Colors.downloadedBtn : Colors.downloadBtn};
    color: ${(props) => (props.downloaded ? 'white' : Colors.fontDefault)};
    border: none;
    cursor: ${(props) => (props.downloaded ? 'initial' : 'pointer') };
    font-weight: bold;
    border-radius: 8px;
    text-align: center;
    text-decoration: none;
  }
`;
