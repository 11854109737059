import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 50px;

  img {
    width: 70px;

    @media (max-width: 800px) {
      width: 70px;
    }
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;

  > button + a {
    margin-left: 30px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    > button + a {
      margin-left: 0;
      margin-top: 20px;
    }
  }
`;

export const ReturnBtn = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;

  a {
    color: ${Colors.fontDefault};
    text-decoration: none;
  }

  a:visited {
    color: ${Colors.fontDefault};
  }
`;

export const AcceptTermsWrapper = styled.div`
  div + div {
    margin-top: 10px;
  }
`;

export const ErrorMessage = styled.p`
  margin-top: -50px;
  margin-bottom: 50px;
  color: ${Colors.error};
  font-size: 14px;
  position: absolute;
`;

export const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  div > p{
    color: white;
    padding: 20px;
    text-align: center;
  }

`

export const Error = styled.div`

> p{
  background-color: ${Colors.error};
}

`

export const Success = styled.div`

> p{
  background-color: ${Colors.success};
}
`