import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import { Helmet } from 'react-helmet';
import Colors from '../../styles/Colors';
import Button from '../../components/Button';
import { useHistory } from 'react-router';
import Footer from '../../components/Footer';
import Logo from '../../assets/Logo.svg'

const NotFoundWrapper = styled.div`
margin-top: 40px;
display: flex;
flex-direction: column;
align-items: center;

p {
  text-align: center;
  line-height: 26px;
  font-size: 15px;
  margin-top: 50px;
  
  bold{
    font-weight: bold;
    color: ${Colors.fontDefault};
  }
}

button{
  margin-top: 80px;
}

`;

const TitleSection = styled.h2`
text-align: justify;
font-size: 130px;
text-transform: uppercase;
font-family: 'Montserrat',sans-serif;
color: ${Colors.fontDefault};


@media (max-width: 600px){
  font-size: 150px;
}
`;

export default function Maintenance() {
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Manutenção | ViAssim</title>
      </Helmet>
      {/*<Header />*/}
      <BaseMain>
        <NotFoundWrapper>
          <img src={Logo} />
          {/*<TitleSection>Manunteção</TitleSection>*/}

          <p>Estamos em manutenção e em breve estaremos de volta.</p>
          <p>Agradecemos a compreensão</p>

        </NotFoundWrapper>
      </BaseMain>
      {/*<Footer />*/}
    </>
  );
}
