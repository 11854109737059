import React from 'react';
import styled from 'styled-components';
import Colors from '../../styles/Colors';

const Field = styled.input`
  width: 100%;
  border: none;
  border-bottom: 2px solid ${Colors.goldDefault};
  margin: 12px 0 70px;
  font-size: 16px;
  background-color: transparent;

  &::placeholder {
    font-style: italic;
  }

  &:focus {
    outline: none;
  }
`;

const Label = styled.label`
  font-size: 14px;
  color: ${Colors.fontDefault};
  font-weight: bold;
`;

export default function Input(props) {
  return (
    <span>
      <Label htmlFor={props.id}>{props.label}</Label>
      <Field
        as={props.as}
        rows={props.rows}
        required={props.required}
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        onChange={props.onChange}
        name={props.name}
        value={props.value}
        onFocus={props.onFocus}
      />
    </span>
  );
}
