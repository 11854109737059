import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Container = styled.div`
  width: 60%;
  margin: 0 auto;
  padding: 50px 10px 0;

  @media (max-width: 850px) {
    display: flex;
    justify-content: center;
  }
`;

export const LikeBtn = styled.button`
  width: 115px;
  height: 35px;
  border: 1px solid ${Colors.redDefault};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  background-color: ${(props) => (props.liked ? Colors.redDefault : 'white')};
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  margin-right: 30px;
  color: ${(props) => (props.liked ? 'white' : Colors.fontDefault)};

  svg {
    margin-right: 5px;
    color: ${(props) => (props.liked ? 'white' : Colors.redDefault)};
  }

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: ${(props) => (props.liked ? 'white' : Colors.redDefault)};
    color: ${(props) => (props.liked ? Colors.fontDefault : 'white')};

    svg {
      color: ${(props) => (props.liked ? Colors.redDefault : 'white')};
    }
  }
`;

export const InfoBar = styled.div`
  display: flex;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    margin-right: 12px;

    p {
      font-family: 'Montserrat', sans-serif;
      margin-left: 3px;
    }
  }

  div:first-of-type{
    cursor: pointer;
  }

  div + div {
    margin-right: 0;
  }
`;

export const Popup = styled.div`
    position:fixed;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    z-index: 40;
    bottom: 0;
    left:0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    > div{
        width: 25%;
        background-color: white;
        height: 400px;
        text-align: center;
        border-radius: 10px;
        border-left: 0;
        position: relative;
        overflow: auto;

        @media(max-width:1400px){
            width: 40%;
            min-width: 300px;
        }

        a{
            display: flex;
            // justify-content: center;
            align-items: center;
            padding-top: 30px;
            color: ${Colors.fontDarker};
            text-decoration: none;
            margin-left: 20px;
        
            
            
            p{
                margin-top: 5px;
                margin-left: 10px;
            }
        }
    }
    
    a::visited{
        color: inherit;
    }

`

export const TitlePage = styled.h3`
    text-align: center;
    display: block;
    background-color: ${Colors.goldDefault};
    padding:15px;
    font-family: 'Montserrat',sans-serif;
`
export const CloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
    font-family: 'Montserrat',sans-serif;
`

export const UsersWrapper = styled.div`
    
`