import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import Title from '../../components/Title';
import { Helmet } from 'react-helmet';
import Colors from '../../styles/Colors';
import Footer from '../../components/Footer';

const FaqWrapper = styled.div`
  margin-top: 40px;
  padding-bottom: 80px;

  p {
    text-align: justify;
    line-height: 26px;
    font-size: 15px;
    margin-bottom: 20px;

    bold{
      font-weight: bold;
      color: ${Colors.fontDefault};
    }
  }
  
`;

const Question = styled.h2`
    text-align: justify;
    line-height: 26px;
    font-size: 16px;
    text-transform: uppercase;
    font-family: 'Merriweather',serif;
    color: ${Colors.fontDarker};
    margin: 40px 0 20px;
`;

const QuestionWrapper = styled.div`
  border-bottom: 1px solid ${Colors.goldDefault}
`

export default function Faq() {
  return (
    <>
      <Helmet>
        <title>FAQ | ViAssim</title>
      </Helmet>
      <Header />
      <BaseMain>
        <Title text='FAQ - ViAssim' />
        
        <FaqWrapper>
          <p>As perguntas mais frequentes sobre a plataforma e o uso do site ViAssim</p>
          
            <QuestionWrapper>
                <Question>Preciso me cadastrar para navegar na plataforma?</Question>
                <p>Para navegar não há necessidade de cadastro. Você poderá olhar as fotos, procurar imagens, mas para interagir - dar like, deixar mensagem, vender ou comprar fotos – é preciso ter cadastro na plataforma.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question> Posso me cadastrar gratuitamente?</Question>
                <p>Sim. Com o cadastro gratuito, você pode navegar, interagir - dar likes, colocar comentários e fazer o upload ilimitadamente de fotos. No entanto, o cadastro gratuito permite colocar à venda apenas 10 fotos.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question> Quais as vantagens de me tornar PRO?</Question>
                <p>Você pode subir e colocar número ilimitado de fotos à venda. Poderá ter uma de suas fotos como destaque da semana no site, o que aumenta suas chances de venda, pois a fotografia ficará na home do site. Além disso, receberá um e-book com dicas de marketing digital que ajudarão a aumentar as suas oportunidades de vendas. Caso queira, poderá receber também uma leitura de portfólio gratuita feita por um profissional do mercado, com a avaliação das suas fotos.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Plano PRO é pago mensalmente?</Question>
                <p>O plano PRO é pago uma única vez por ano e tem renovação automática.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Posso cancelar ou excluir minha conta a qualquer tempo?</Question>
                <p>Sim, a qualquer momento pode cancelar o plano PRO e ficar apenas no gratuito ou mesmo excluir a conta. Caso já tenha pago por uma anuidade do plano PRO, o dinheiro do período restante não será devolvido. Cancelando a renovação automática, o fotógrafo tera acesso aos benefícios do PRO até o final da validade da anuidade.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Única forma de me tornar PRO é pagando uma anuidade?</Question>
                <p>Não. Existem duas formas para se tornar PRO. Você pode pagar a anuidade ou comprar 3 fotos de qualquer fotógrafo dentro do site, exceto as suas.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Como o sistema define qual foto aparece primeiro nas buscas?</Question>
                <p>Os usuários PRO aparecem primeiro nas buscas, mas a busca abrange todos os fotógrafo.
                </p>
            </QuestionWrapper>   

            <QuestionWrapper>
                <Question>ViAssim é uma empresa brasileira?</Question>
                <p>Sim, empresa 100% brasileira.
                </p>
            </QuestionWrapper> 

            <QuestionWrapper>
                <Question>Quem determina o valor das fotos no site?</Question>
                <p>O próprio fotógrafo determina o valor de suas fotos. Entendemos que como autor e legítimo proprietário do direito autoral, o fotógrafo deve receber pela foto o valor que ele próprio determina.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Como a ViAssim é remunerada pelos fotógrafos?</Question>
                <p>Além da anuidade, a ViAssim cobra do comprador, 20% sobre o valor de venda determinado pelo fotógrafo.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Como o comprador recebe a foto?</Question>
                <p>Após o pagamento a foto ficará disponível para download na pagina Downloads. Só é permitido fazer o download uma vez, então recomendamos salvar a foto em local seguro.
                </p>
            </QuestionWrapper>   

            <QuestionWrapper>
                <Question>Como o comprador pode pagar por uma foto?</Question>
                <p>É possível pagar com cartão de crédito.
                </p>
            </QuestionWrapper>

             <QuestionWrapper>
                <Question>Preciso ser fotógrafo profissional para postar minhas fotos?</Question>
                <p>Definitivamente não. A ViAssim é um ambiente democrático para fotógrafos de qualquer nível, do estudante ao profissional, para aparecerem em igualdade e todos com a mesma atenção.
                </p>
            </QuestionWrapper>      

            <QuestionWrapper>
                <Question>Existe tema proibido para postagens?</Question>
                <p>Não é permitido postagens de pornografia.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Posso postar pinturas, ilustrações ou outras expressões artísticas?</Question>
                <p>A ViAssim é uma plataforma exclusiva para fotografia.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Posso postar e vender fotos onde apareçam pessoas?</Question>
                <p>Postar sim, como qualquer rede social. Vender foto onde apareça pessoa reconhecível, somente se tiver autorização assinada da pessoa. Há um espaço para subir autorização junto com a foto.
                </p>
            </QuestionWrapper> 

            <QuestionWrapper>
                <Question>Posso subir foto com marcas d’água?</Question>
                <p>Não
                </p>
            </QuestionWrapper>  

            <QuestionWrapper>
                <Question>A ViAssim é uma rede social ou um site de vendas?</Question>
                <p>Somos uma rede social de fotografia, onde se pode dar likes, colocar comentários e compartilhar. Mas, também somos um MarketPlace de fotografia, onde as fotos postadas podem ser vendidas ou compradas por qualquer um.
                </p>
            </QuestionWrapper>

            <QuestionWrapper>
                <Question>Posso subir minhas fotos em lote?</Question>
                <p>Nós da ViAssim acreditamos que a fotografia é uma peça de arte e, como peça de arte, queremos que toda a foto que seja colocada no site passe pelo processo cuidadoso de upload, adição de tags e preços (caso esteja à venda). Acreditamos que subir fotos em lote faz com que esse processo se perca um pouco e se aproxime mais de uma experiência de uso um banco de imagens convencional.
                </p>
            </QuestionWrapper>


        </FaqWrapper>
      </BaseMain>
      <Footer />
    </>
  );
}
