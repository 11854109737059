import styled from "styled-components";
import Colors from "../../styles/Colors";

export const FollowersDisplayWrapper = styled.div`
    display: flex;

    div{
        cursor: pointer;
    }
    
    div + div{
        margin-left: 20px;
    }

    >:last-child{
        p{
            
            border-left: 2px solid black;
            padding-left: 20px;
        }

    }
`

export const Popup = styled.div`
    position:fixed;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    z-index: 40;
    bottom: 0;
    left:0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    > div{
        width: 25%;
        background-color: white;
        height: 400px;
        text-align: center;
        border-radius: 10px;
        border-left: 0;
        position: relative;
        overflow: auto;

        @media(max-width:1400px){
            width: 40%;
            min-width: 300px;
        }

        a{
            display: flex;
            // justify-content: center;
            align-items: center;
            padding-top: 30px;
            color: ${Colors.fontDarker};
            text-decoration: none;
            margin-left: 20px;
        
            
            
            p{
                margin-top: 5px;
                margin-left: 10px;
            }
        }
    }
    
    a::visited{
        color: inherit;
    }

`

export const TitlePage = styled.h3`
    text-align: center;
    display: block;
    background-color: ${Colors.goldDefault};
    padding:15px;
`
export const CloseButton = styled.div`
    position: absolute;
    top: 15px;
    right: 30px;
    cursor: pointer;
`

export const UsersWrapper = styled.div`
    
`