import styled from 'styled-components';

export const Grid = styled.div`
  margin-top: 70px;
  margin-bottom: 200px;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 50px;
  row-gap: 130px;
`;
