import styled from "styled-components";

export const Paragraph = styled.p`
    margin-bottom: 20px;
    text-align: justify;
    font-size: 12px;
    line-height: 25px;

    bold{
        font-weight: bold;
    }
`

export const Title = styled.h2`
    margin-bottom: 30px;
`