import styled from 'styled-components';

export const BtnWrapper = styled.div`
  display: flex;

  > a + a,
  > a + div {
    margin-left: 20px;
  }

  @media (max-width: 950px) {
    > a + a,
    > a + div {
      margin-left: 10px;
    }
  }

  @media (max-width: 850px) {
    display: none;
  }
`;
