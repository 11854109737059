import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const WrapperMain = styled.main`
  width: 70%;
  margin: 0 auto;
  padding: 60px 0 20px;

  h2{
    font-size: 20px;
    color: ${Colors.fontDefault};
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 30px;
  }
`;

export const PhotographerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin-top: 32px;
  }

  button {
    /* align-self: flex-end; */
    margin-top: 30px;
    left: 110px;
    margin-left: auto;
    margin-right: 20px;
  }
`;

export const Bio = styled.p`
  max-width: 1000px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
`;


export const AlbumWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;
  p{
    width: 240px;
    background-color: ${Colors.redDefault};
    display: block;
    padding: 50px; 
    cursor: pointer;
    color: white;
    font-weight: bold;
  }

  p + p{
    margin-left: 30px;
  }
`

export const ReturnButton = styled.p`
  position: absolute;
  display: block;
  left: 15%;
  font-size: 13px;
  font-family: 'Montserrat', sans-serif;
  color: ${Colors.fontDefault};
  cursor: pointer;
  font-weight: bold;
  border-bottom: 1px solid ${Colors.redDefault};

  @media (max-width: 600px){
    top: 220px;
  }

`

export const HasNoPhotosWarning = styled.p`
  display: block;
  margin-top: 60px;
  text-align: center;
`

export const DeleteButtonWrapper = styled.div`
  position: absolute;
  right: 15%;
  cursor: pointer;

`

export const DeleteRoom = styled.div`
  margin-left: auto;
  right: 15%;
  margin-top: 20px;
  width:100px;
  background: ${Colors.redDefault};
  padding: 10px 5px;
  cursor: pointer;
  font-size: 13px;
  text-align: center;
  color: white;
`

export const DeleteWindow = styled.div`
    position:fixed;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    z-index: 40;
    bottom: 0;
    left:0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    > div{
        width: 50%;
        background-color: white;
        height: 400px;
        text-align: center;
        border-radius: 10px;
        display: flex;
        flex-direction:  column;
        aligm-items: center;
        justify-content: center;

        >div{
          margin: 0 auto;
        }
    }

    span{
      margin-top: 30px; 
    }

`

export const ErrorWarning = styled.p`
    margin-top: 50px;
    background-color: ${Colors.error};
    width: auto;
    color: white;
    text-align: center;
    margin: 50px auto 0;
    padding: 20px;
`