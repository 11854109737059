import styled from "styled-components";
import Colors from "../../styles/Colors";

export const SelectPage = styled.div`
  display: flex;
  margin-top: 40px;
  border-bottom: 4px solid ${Colors.goldDefault};
  margin-bottom: 40px;
`;

export const SelectSearchButtons = styled.div`
  min-width: 100px;
  text-align: center;
  padding: 15px 30px;
  text-decoration: none;
  color: initial;
  background-color: ${(props) =>
    props.highlight ? Colors.goldDefault : 'transparent'};
`;
