import styled from 'styled-components'
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  margin-top: 120px;
  margin: 0 auto;
  margin-bottom: 50px;
  padding: 60px 0 20px;
  width: 70%;

    h1{
        margin-bottom: 40px;
    }

  

  @media (max-width: 550px) {
    margin-top: 70px;
    margin-bottom: 0;
		width: 85%;
		padding-top: 0;
  }
`;

export const ProWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 60px;
  margin-top: 40px;

  img{
    width: 100px;

		@media (max-width: 550px){
			width: 70px;
		}
  }

  span{
      margin-top: 20px;
  }


`

export const ProBadge = styled.span`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  background-color: ${Colors.redDefault};
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
	margin-left: 10px;

	@media (max-width: 550px){
		font-size: 12px;
		padding: 8px 16px;
	}

`

export const IconsWrapper = styled.div`

	display: flex;
	justify-content: space-between;
	margin: 0 auto 100px ;
	max-width: 800px;

	div + div{
		margin-left: 80px;
	}


	> div{
		width: 70%;
		display: flex;
		flex-direction: column;
		align-items: center;

		img{
			width: 100%;
			margin: 0 auto 10px;

		}

		p{
			text-align: center;
			font-family: 'Montserrat', sans-serif;
			line-height: 24px;
			font-size: 14px;
		}

	}

	@media (max-width: 550px){
		flex-direction: column;
		align-items: center;

		div + div{
			margin-left: 0;
			margin-top: 20px;
		}

		>div {
			width: 50%;
		}
	}


`

export const BtnWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 30px;
	align-items: center;
	margin: 50px auto;

	p{
		text-align: center;
		font-family: 'Montserrat', sans-serif;
		line-height: 24px;
		font-size: 14px;
		border-bottom: 2px solid ${Colors.redDefault};
	}

	> a {
		margin-top: 20px;
		text-decoration: none;
		color: ${Colors.fontDefault};
		font-family: 'Montserrat', sans-serif;
		font-size: 14px;

		:visited{
			color: initial;	
		}
	}

`

export const Error = styled.div`
	margin-top: 20px;
	margin-bottom: -30px;
	background-color: ${Colors.error};
	padding: 10px;
	color: white;
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
`

export const Section = styled.div`

	margin-bottom: 80px;

	p {
		text-align: justify;
		line-height: 30px;
		font-family: 'Montserrat', sans-serif;
		font-size: 16px;
	
		@media (max-width: 400px) {
		  font-size: 14px;
		}
	
	}

	
	> p + p{
		margin-top: 20px;    
	}
`