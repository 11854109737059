import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const WrapperLoadingButton = styled.div`
    display: flex;
    margin: 30px auto;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 300px;

    p{
        margin-top: 20px;
        font-size: 15px;
        margin-bottom: 40px;
    }
`


export const Button = styled.button`
    padding: 10px 50px;
    background-color: ${Colors.redDefault};
    color: white;
    border: none;
    border-radius: 3px;
    cursor pointer;

    &:hover{
        opacity: 0.8;
    }
`
