import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const WrapperMain = styled.main`
  width: 70%;
  margin: 0 auto;
  padding: 60px 0 20px;

  h2{
    font-size: 20px;
    color: ${Colors.fontDefault};
    font-weight: bold;
    margin-bottom: 20px;
    margin-top: 30px;
  }


  @media (max-width: 950px){
    width: 80%;
  }
`;

export const PhotographerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  p {
    margin-top: 32px;
  }

  button {
    /* align-self: flex-end; */
    margin-top: 30px;
    left: 110px;
    margin-left: auto;
    margin-right: 20px;
  }
`;

export const Bio = styled.p`
  max-width: 1000px;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 30px;
`;


export const AlbumWrapper = styled.div`
  display: flex;
  margin-bottom: 60px;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;

  a{
    text-align: center;
    text-decoration: none;
    width: 22%;
    min-width: 200px;
    height: 200px;
    background: ${Colors.goldDefault};
    background: linear-gradient(101.89deg, ${Colors.blueDefault} 8.33%, ${Colors.goldDefault} 80.68%);
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 10px; 
    cursor: pointer;
    color: white;
    font-weight: bold;
    margin-bottom: 40px;
    margin-right: 20px;


    p{
      background-color: rgba(0, 0, 0, 0.4);
      padding: 10px 30px;
      width: 90%;
    }

    
    @media (max-width: 1200px){
      width: 30%;
    }

    
  }
  
      @media (max-width: 950px){
        justify-content: space-between;
        a{
          width: 45%;
          margin-right:0;

        }
      }


      @media (max-width: 500px){
        justify-content: center;
        a{
          width: 45%;
          margin-right:0;

        }
      }
  
  
`


export const ProBadge = styled.span`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  background-color: ${Colors.redDefault};
  border-radius: 10px;
  color: white;
  font-weight: bold;
  font-size: 12px;
  padding: 8px 16px;
  margin-bottom: -20px;
  margin-top: 20px;
`