import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  /* gap: 20px; */
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;

  > a + div,
  > div + div {
    margin-left: 20px;
  }

  a {
    text-transform: uppercase;
    color: ${Colors.fontDefault};
    text-decoration: none;
  }

  @media (max-width: 850px) {
    display: none;
  }
`;

export const Cart = styled.div`
  display: flex;
  /* gap: 5px; */

  a {
    display: flex;

    > svg + p {
      margin-left: 5px;
    }
  }

  svg {
    color: ${Colors.fontDefault};
  }
`;
