import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  position: absolute;
  display: flex;
  justify-content: center;
  border-bottom: 8px solid ${Colors.blueDefault};
  padding-top: 50px;
  padding-bottom: 50px;
  z-index: 50;
  /* gap: 2%; */
  > div + div {
    margin-left: 2%;
  }

  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }
`;

export const Content = styled.div`
  width: 350px;
  background-color: ${(props) => props.backgroundColor};
  color: white;
  padding: 30px 0;
  border-radius: 8px;

  p {
    font-size: 18px;
    font-weight: bold;
  }

  p + p {
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 30px;
  }

  li {
    padding: 0 20px;
    margin-top: 10px;
    line-height: 26px;
    text-align: left;
  }

  @media (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;
