import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
    position:fixed;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100vh;
    z-index: 40;
    bottom: 0;
    left:0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    
    > div{
        width: 50%;
        background-color: white;
        height: 400px;
        text-align: center;
        border-radius: 10px;
    }

`

export const Title = styled.p`
    margin-top: 30px;
    font-size: 25px;
    font-family: 'Merriweather', sans-serif;
    padding-bottom: 30px;
    border-bottom: 2px solid ${Colors.error};
    margin-bottom: 50px;
`

export const WarningText = styled.p`
    margin: 10px 0;
`

export const ButtonWrapper = styled.div`
    margin-top: 30px;
    display: flex;
    justify-content: space-around;
    width: 50%;
    margin: 80px auto 0;
`

export const DownloadWrapper = styled.div`
    a{
        display: block;
        background-color: ${Colors.goldDefault};
        padding: 20px 30px;
        border-radius: 5px;
        text-decoration: none;
        margin-top: -30px;
        color: ${Colors.fontDefault};
        font-size: 15px;

        &:hover{
            opacity: 0.8;

        }

        &:visited{
            color: ${Colors.fontDefault};
        }
    }

    

    p{
        margin-top: 60px;
        font-size: 14px;
        color: ${Colors.fontDefault};
        cursor: pointer;
    }

`