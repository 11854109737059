import styled from 'styled-components';

export const ImageContainer = styled.div`
  width: 50%;

  @media (max-width: 1100px) {
    display: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url(${(props) => props.image});
  position: relative;

  @media (max-width: 1100px) {
    display: none;
  }

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.6;
    z-index: 0;
    position: absolute;
  }
`;

export const Content = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  padding: 100px;
  position: absolute;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    font-family: 'Merriweather', serif;
    font-size: 60px;
    max-width: 60%;
    display: block;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 100px;


    @media (max-width: 1500px){
      max-width: 80%;
      font-size: 40px;
    }
  }

  p {
    font-size: 25px;
    line-height: 40px;
    font-family: 'Montserrat', sans-serif;

    @media (max-width: 1500px){
      max-width: 80%;
      font-size: 20px;
    }
  }

  p + p {
    margin-top: 20px;
  }
`;
