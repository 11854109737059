import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const ErrorText = styled.p`
  margin-top: 70px;
  line-height: 30px;
  font-size: 20px;
  color: ${Colors.fontDefault};
`;

export const UserSearchWrapper = styled.div`
  margin-top: 70px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
`;

export const SelectSearchBarWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  border-bottom: 4px solid ${Colors.goldDefault};
`;

export const SelectSearchButtons = styled.div`
  min-width: 100px;
  text-align: center;
  padding: 15px 10px;
  text-decoration: none;
  color: initial;
  background-color: ${(props) =>
    props.highlight ? Colors.goldDefault : 'transparent'};
`;

