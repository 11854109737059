import styled from "styled-components";
import Colors from "../../styles/Colors";

export const WrapperSoldPhoto = styled.div`
    display: flex;    
    margin-bottom: 40px;

    > img{
        width: 30%;
        max-width: 300px;
        height: 100%;
    }
    

    @media (max-width: 550px){
        flex-direction: column;
        align-items: center;

        img{
            width:80%;
            margin-bottom: 30px;
        }
    }
`

export const DataWrapper = styled.div`
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    p{
        margin-bottom: 15px;
    }

    a{
        color: ${Colors.fontDarker}
    }
`