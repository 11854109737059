import styled from "styled-components";
import Colors from "../../styles/Colors";

export const ProTableWrapper = styled.div`
    display: flex;
    justify-content: center;
    max-width:950px;
    margin: 100px auto 50px;

    @media (max-width: 1300px){
        max-width: initial;
        width: 100%;
        justify-content: space-between;
    }

    @media (max-width: 890px){
            flex-direction: column;
            align-items: center;
    }

`

export const Content = styled.div`
    height: 600px;
    width: 45%;

    > p{
        font-size:18px;
        font-family: 'Montserrat',sans-serif;
        margin-bottom: 20px;
        text-align: center;
    }

    @media (max-width: 890px){
       width: 80%;
       margin-bottom: 40px;
       height: auto;

    }

    @media (max-width: 550px){
        width: 100%;
        margin-bottom: 40px;
        height: auto;

    }

`

export const Table = styled.div`
    max-width: 400px;
    margin: 0 auto;
    border: 2px solid ${props => props.pro ? Colors.redDefault : Colors.fontDarker};
    padding: 0 20px 20px ;
    height: 550px;
    position: relative;

    @media(max-width: 1300px){
        max-width: 350px;
    }

    @media (max-width: 890px){
        max-width: 90%;
        height: auto;
        padding-bottom: 220px;
 }
    
`



export const Item = styled.p`
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 26px;
    margin-top: 20px;
    border-bottom: 1px solid ${Colors.fontDarker};
    padding: 10px;
`

export const ProBadge = styled.span`
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  background-color: ${Colors.redDefault};
  padding: 10px 20px;
  border-radius: 10px;
  color: white;
  font-weight: bold;
	margin-left: 10px;

	@media (max-width: 550px){
		font-size: 12px;
		padding: 8px 16px;
	}

`

export const PriceWrapper = styled.div`
    position: absolute;
    display: block;
    width: 100%;
    bottom: 0;
    left:0;
    padding: 20px;
    text-align: center;
    color: white;
    height:100px;
    background: ${props => props.pro ? Colors.redDefault : Colors.fontDarker};

    p{
        font-family: 'Montserrat', sans-serif;
        font-size: 20px;
    }

    span{
        display: block;
        margin-top: 15px;
    }

    span > p{
        font-size: 13px;
    }
`