import styled from "styled-components";
import Colors from "../../styles/Colors";

export const PurchaseItemWrapper = styled.div`
    > p{
        margin-bottom: 16px;
    }

    margin-bottom:50px;
    padding-bottom: 30px;
    border-bottom: 3px solid ${Colors.blueDefault}
`

export const ItemsWrapper = styled.div`

    margin-top: 20px;
    margin-bottom: 30px;

    > p{
        margin-bottom: 10px;
        margin-left: 20px;
    }

    a{
        color: ${Colors.fontDarker}
    }

`