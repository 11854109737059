import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  margin-top: -3px;
  background-color: ${(p) =>
    p.sale ? Colors.blueDefault : Colors.goldDefault};
  padding: 5px;
  display: flex;
  align-items: center;
`;

export const Button = styled.button`
  padding: 5px 15px;
  cursor: pointer;
  border: none;
  border-radius: 3px;
  background-color: white;

  &:focus {
    outline: none;
    box-shadow: none;
  }

  svg {
    color: ${Colors.redDefault};
    fill: ${(p) => (p.liked ? Colors.redDefault : 'transparent')};
  }
`;

export const SellIcon = styled.div`
  padding: 5px;
  margin-left: 10px;
  background-color: ${Colors.goldDefault};
  color: ${Colors.fontDefault};
  border-radius: 8px;
`;

export const User = styled.div`
  width: 100%;
  height: 100%;
  margin-right: 5px;

  a {
    height: 100%;
    width: 100%;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-decoration: none;
    color: ${Colors.fontDefault};

    > div + p {
      margin-left: 10px;
    }
  }

  a:visited {
    color: ${Colors.fontDefault};
  }
`;

export const PhotoInfo = styled.div``;
