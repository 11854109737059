import React from 'react';
import ReactDOM from 'react-dom';
import GlobalStyle from './styles/GlobalStyle';
import Routes from './routes';
import { UserProvider } from './providers/user';
import { CartItemProvider } from './providers/cartItem';
import {Helmet} from 'react-helmet'
import {disableRightClick} from './utils/disableRightClick';


ReactDOM.render(
  <React.StrictMode>
    <UserProvider>
      <CartItemProvider>
        <Helmet>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link href="https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&family=Montserrat:wght@300;400;700&display=swap" 
          rel="stylesheet" /> 
        </Helmet>
        <GlobalStyle />
        <Routes />
        {disableRightClick()}
      </CartItemProvider>
    </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
