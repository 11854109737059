import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import Title from '../../components/Title';
import { Helmet } from 'react-helmet';
import Colors from '../../styles/Colors';
import Footer from '../../components/Footer';

const PrivacyTermsWrapper = styled.div`
  margin-top: 40px;

  p {
    text-align: justify;
    line-height: 26px;
    font-size: 15px;

    bold{
      font-weight: bold;
      color: ${Colors.fontDefault};
    }
  }

  li{
    list-style:disc;
    margin-left: 60px;
  }
  
`;

const TitleSection = styled.h2`
    text-align: justify;
    line-height: 26px;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Merriweather',serif;
    color: ${Colors.fontDefault};
    margin: 40px 0 20px;
`;

const SubTitle = styled.h2`
    text-align: justify;
    line-height: 26px;
    font-size: 18px;
    font-family: 'Merriweather',serif;
    color: ${Colors.fontDefault};
    margin: 40px 0 20px;
`;

export default function PrivacyTerms() {
  return (
    <>
      <Helmet>
        <title>Termos de Privacidade | ViAssim</title>
      </Helmet>
      <Header />
      <BaseMain>
        <Title text='Termos de privacidade' />
        
        <PrivacyTermsWrapper>
          <p>Versão de: OUTUBRO de 2021</p>
          <br></br>
          <p>Nossa Política de Privacidade explica:</p>
          <br></br>
          <br></br>
          <ul>
            <li><p>Quais informações coletamos e por que coletamos.</p></li>
            <li><p>Como usamos essas informações</p></li>
          </ul>
          <br></br>
          <br></br>  
          <p>
          Somos um MarketPlace que funciona mediante conexão à internet e que tem como objetivo intermediar a venda e compra de Obras Fotográficas.</p>
          <br></br>
          <p>
          Sua privacidade é importante para nós, portanto, sendo você um usuário, por favor, reserve um tempo para conhecer nossas práticas. E, se você tiver alguma dúvida, consulte esta página ou os <a href="https://www.viassim.com.br/usage" target="_blank">Termos de Uso</a>.
          </p>
          <br></br>
          <p>
          Fazemos referência à empresa através do uso de termos tais como “site”, “nós”, “nosso”, “conosco”, palavras tais como “você”, “seu”, e expressões similares referem-se a nossos clientes, ou a usuários de nosso site. Ao visitar e usufruir do site, você automaticamente aceita as condições descritas a seguir, que compõem a Política de Privacidade.
          </p>
          <br></br>
          <TitleSection>INFORMAÇÕES QUE COLETAMOS</TitleSection>
          <p>
          Importante: Você não é obrigado a conceder informações ao nosso site. Entretanto, sem conceder certas informações quando estas forem solicitadas, não lhe será permitido o acesso a algumas funcionalidades. Coletamos informações para fornecer serviços melhores a todos nossos usuários e elas ocorrem da seguinte forma:
          </p>
          <SubTitle>1. Coleta de Informação Pessoal </SubTitle>
          <br></br>
          <p>
          A VIASSIM pode coletar informações de identificação pessoal, como nome, sobrenome, e-mail, senha, número de telefone, foto, CPF e dados para fins de pagamento*.
          </p>
          <br></br>
          <p>
          A VIASSIM irá utilizar essas informações para processar e completar seu acesso ao site. Tais dados poderão ser armazenados. Dessa maneira, de acordo com o art. 7º, inciso VIII da Lei 12.965/2014 (Marco Civil da Internet), poderemos enviar mensagens.
          </p>

          <SubTitle>2. Uso da sua informação pessoal </SubTitle>
          <br></br>
          <p>
          Sempre que você acessar e utilizar a nossa plataforma de serviços, a VIASSIM receberá e armazenará automaticamente informações, incluindo seu endereço IP, informações de cookies, que serão relacionadas com as informações pessoais que você fornece.
          </p>
          <br></br>
          <p>
          A VIASSIM irá disponibilizar suas informações pessoais, sem aviso prévio, somente em caso de: (a) legislação, regulamentação, processo legal ou solicitação governamental aplicável; (b) cumprir investigação de possíveis violações; (c) fraude ou por segurança; ou (d) proteger contra dano aos direitos, a propriedade ou a segurança da VIASSIM, nossos usuários ou o público, conforme solicitado ou permitido por lei.
          </p>
          <br></br>
          <p>
          A VIASSIM efetuará o cadastro do usuário, mantendo em seu sistema os registros específicos das Transações efetuadas.
          </p>
          <br></br>
          <p>
          Os dados cadastrais ativos e atualizados do Titular, tais como, mas não limitado ao nome completo e respectivo número de inscrição no CPF ou CNPJ, data de nascimento (ou constituição, no caso de empresas), endereço residencial e/ou comercial e número do celular e código DDD, a fim de permitir a sua correta identificação e dos montantes depositados na Conta.
          </p>
          <br></br>
          <p>
          Todos os documentos e informações relacionados ao cadastro do Titular permanecerão em segurança na posse da VIASSIM. 
          </p>
          <br></br>
          <p>
          O Titular autoriza a VIASSIM a transmitir alguns dados cadastrais, tais como nome completo, CPF, endereço de e-mail e número do cartão de crédito, para fins de pagamento*. 
          </p>
          <br></br>
          <p>
          A VIASSIM é uma empresa privada e, com isso, se reserva o direito de, a seu exclusivo critério e segundo seus parâmetros de análise cadastral, aceitar ou não a adesão do Titular. 
          </p>

          <br></br>
          <p>
          A VIASSIM se reserva o direito de bloquear ou cancelar a Conta em virtude da existência de indícios ou de ilícito efetivo identificado, além de divergências cadastrais. 
          </p>


          <SubTitle>3. Compartilhamento de informações com terceiros</SubTitle>
          <br></br>
          <p>
          A VIASSIM não irá repassar, alugar ou vender seu endereço de e-mail. Nós não compartilharemos suas informações pessoais com outras empresas, em atenção à Lei 12.965/2014, salvo com sua expressa autorização ou em caso de ordem judicial. Nós não compartilhamos informações de cartões de créditos com outras empresas, exceto com as responsáveis pelo processamento do pagamento, como é previsto por lei. Nós não nos responsabilizamos pela forma com que terceiros coletam, usam, protegem ou revelam as informações que você possa vir a fornecê-los. 
          </p>

          <SubTitle>4. Restrição de idade</SubTitle>
          <br></br>
          <p>
          A VIASSIM foi idealizada para consumidoras maiores de idade. Se você tiver menos de 18 anos de idade, não será permitido o envio de suas informações pessoais ao nosso site. 
          </p>

          <SubTitle>5. Segurança</SubTitle>
          <br></br>
          <p>
          Nós dispomos de medidas de segurança em âmbitos físico, eletrônicos e administrativos, que protegem os dados pessoais e suas informações. Essas medidas de proteção nos auxiliam na prevenção de fraudes e acessos não autorizados às informações, bem como na manutenção da integridade dos dados. Além disso, dispomos de uma Política de Segurança Interna para que funcionários da VIASSIM não tenham acesso a suas informações pessoais, de modo que seus dados permaneçam sempre sem segurança. 
          </p>

          <SubTitle>6. Direitos dos usuários</SubTitle>
          <br></br>
          <p>
          Todos os direitos elencados abaixo podem ser exercidos em qualquer momento através de solicitação diretamente no site.  
          </p>

          <br></br>
          <p>
          <bold>Acceso</bold>. O usuário poderá solicitar informações a respeito de quais dados são armazenados e para qual finalidade.  
          </p>

          <br></br>
          <p>
          <bold>Retificação</bold>. O usuário poderá solicitar a correção/atualização de algum de seus dados pessoais, pois é sua responsabilidade mantê-los sempre atualizado.  
          </p>

          <br></br>
          <p>
          <bold>Eliminação</bold>. O usuário poderá solicitar a eliminação dos seus dados pessoais nas seguintes situações: (i) quando não forem necessários para a finalidade para a qual são utilizados, (ii) quando não estiver mais consentindo com os termos aqui expostos.  
          </p>


          <SubTitle>7. Mudanças nesta Declaração</SubTitle>
          <br></br>
          <p>
          Em caso de modificação destes termos, para atender melhor seus clientes, publicaremos tais alterações da política de privacidade e lhes notificaremos sobre esta situação. Também manteremos as versões anteriores desta Política de Privacidade arquivadas para você compará-las.
          </p>

          <SubTitle>8. Prazo  de armazenamento de dados</SubTitle>
          <br></br>
          <p>
          Os dados serão armazenados enquanto o USUÁRIO mantiver sua conta na plataforma VIASSIM.
          </p>

          <SubTitle>9. Consentimento</SubTitle>
          <br></br>
          <p>
          Os dados serão armazenados enquanto o USUÁRIO mantiver sua conta na plataforma VIASSIM.
          </p>

          <SubTitle>10. Encarregado de Dados (Lei de Proteção de Dados)</SubTitle>
          <br></br>
          <p>
          Durante o período de armazenamento pela VIASSIM,  haverá um responsável exclusivo pelos dados, nos termos do art. 41 da Lei de Proteção de Dados. Esta pessoa irá responder pelos dados, caso haja necessidade de apresentá-los para autoridade competente.
          </p>

          <TitleSection>INFORMAÇÕES QUE COLETAMOS</TitleSection>
          <p>
          A VIASSIM respeita a sua privacidade. Quando se conectam a nós, queremos que nossos clientes saibam que seus dados pessoais e informações de cartão de crédito estão em segurança. Assim como nós nos dedicamos em levar-lhes uma experiência virtual fácil e segura. As informações pessoais armazenadas pelo nosso site nos ajudam a compreender melhor os interesses de nossos clientes, sendo que o nosso esforço em atendê-los com o melhor serviço possível nunca cessa.
          </p>
          <br></br>
          <p>
          Se, mesmo assim, você ficar com alguma dúvida entre em contato: viassim@viassim.com
          </p>

        </PrivacyTermsWrapper>
      </BaseMain>
      <Footer />
    </>
  );
}
