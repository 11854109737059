import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 300px;
  display: flex;
  border: 2px solid ${Colors.goldDefault};
  border-radius: 5px;

  button {
    border: none;
    padding: 5px 10px;
    border-radius: 2px 0 0 2px;
    background-color: ${Colors.goldDefault};
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input {
    border: none;
    border-radius: 3px;
    width: 100%;
    padding-left: 10px;
    font-size: 14px;
  }

  input::placeholder {
    font-size: 12px;
    font-style: italic;
  }

  @media (max-width: 1130px) {
    width: 250px;
  }

  @media (max-width: 850px) {
    width: 80%;
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;
