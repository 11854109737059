import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const Main = styled.main`
  width: 50%;
  padding: 200px 200px;
  min-height: 80%;
  box-sizing: border-box;
`;
