import styled from 'styled-components';

export const Wrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 150%;
    max-height: 150%;
  }
`;
