import React, { useEffect, useState } from 'react';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import Title from '../../components/Title';
import { Helmet } from 'react-helmet';
import Footer from '../../components/Footer';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NotificationCard from '../../components/NotificationCard';
import styled from 'styled-components';
import Colors from '../../styles/Colors';
import { BaseContainer } from '../../containers/BaseContainer';

const WrapperNotification = styled.div`
    background-color: ${Colors.goldDefault};
    border-radius: 4px;
    margin-top: 4px;
    position: relative;
    padding: 20px 80px ;

    a{
        color: ${Colors.fontDefault};
        text-decoration: none;
        border: none;
        display: flex;
        align-items: center;

        @media (max-width: 750px){
            height: 150px;
            padding: 10px;
        }
    }
`

const PageWrapper = styled.div`
    margin-top: 70px;
    margin-bottom: 60px;
`

export default function NotificationsPage(){
    const history = useHistory();
    const [notifications, setNotifications] = useState();

    useEffect( async () => {
        const token = JSON.parse(localStorage.getItem('token'));

        if(!token){
          history.push('/signin');  
        }

        const config = {
            headers:{
                Authorization: `Bearer ${token}`,
            }
        }

        await axios.get(`${process.env.REACT_APP_API_URL}/users/notifications/get-all`, config )
            .then(notifications => {
                setNotifications(notifications.data);
            })
    }, [])
    
    
    return(
        <>
            <Header/>
            <BaseContainer>
            
                <BaseMain>
                <Title text='Minhas notificações'/>
                    <PageWrapper>
                        {notifications ? 
                            <>

                            {notifications.length === 0 ? <p>Você ainda não tem notificações</p> :

                                <>
                                    {notifications.map((notification, index) => {

                                    return(
                                        <WrapperNotification>
                                            <NotificationCard notification={notification} page />
                                        </WrapperNotification>
                                        );
                                    })}
                                </>}


                            </> : null}
                        </PageWrapper>
                </BaseMain>
            
            </BaseContainer>
            <Footer />
        </>
    )
}