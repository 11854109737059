import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const FooterWrapper = styled.div`
`;

export const MainFooter = styled.div`
    background-color: ${Colors.goldDefault};
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p{
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        margin-bottom:20px;
    }

    div > a{
        display: inline-block;
    }

    div > a:focus{
        outline-style: none;
        box-shadow: none;
        border-color: transparent;  
    }

    div > a + a{
        margin-left: 10px;
    }


`;

export const RightsBar = styled.div`
    background-color: ${Colors.darkerGold};
    text-align: center;
    padding: 15px;

    p{
        font-family: 'Montserrat', sans-serif;
        font-size: 12px;  
    }
`
