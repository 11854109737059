import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  width: 100%;

  span {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
  }

  input[type='checkbox'] {
    /* chage size of Checkboxes */
    -ms-transform: scale(1.2); /* IE */
    -moz-transform: scale(1.2); /* FF */
    -webkit-transform: scale(1.2); /* Safari and Chrome */
    -o-transform: scale(1.2); /* Opera */
    transform: scale(1.2);
    padding: 10px;
    margin-right: 10px;
  }
`;
