import styled from 'styled-components'
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
    margin-bottom: 60px;
    margin-top: -20px;

   >  a, > a:visited, >p{
        font-size: 15px;
        font-style: italic;
        margin-bottom: 20px;
        color: ${Colors.fontDefault};
        text-decoration: none;
        display: block;
    }


    input{
        width: 400px;
        border: none;
        border-bottom: 2px solid ${Colors.goldDefault};
        margin-top:20px;
        font-size: 16px;

        &:focus{
            outline: none;
        }
    }
`;



export const SelectionWrapper = styled.div`
    display: flex;
    align-items: center;

    select{
        background-color: white;
        border: none;
        border-bottom: 2px solid ${Colors.goldDefault};
        font-size: 16px;
        cursor: pointer;
    }

`

export const Label = styled.p`
    font-size: 18px;
    color: ${Colors.fontDefault};
    font-weight: bold;
    margin-right: 10px;
`