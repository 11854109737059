import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from '../pages/Home';
import PhotoPage from '../pages/PhotoPage';
import Photographer from '../pages/Photographer';
import Signup from '../pages/Signup';
import UsageTerms from '../pages/UsageTerms';
import PrivacyTerms from '../pages/PrivacyTerms';
import Signin from '../pages/Signin';
import RequestNewPassword from '../pages/RequestNewPassword';
import DownloadPage from '../pages/DownloadPage';
import UploadPage from '../pages/UploadPage';
import AdditionalUserInfo from '../pages/AdditionalUserInfo';
import Cart from '../pages/Cart';
import Search from '../pages/Search';
import SettingsPage from '../pages/SettingsPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import BecomePro from '../pages/BecomePro';
import Album from '../pages/Album';
import Checkout from '../pages/Checkout';
import QuemSomos from '../pages/QuemSomos';
import Transactions from '../pages/Transactions';
import ReturnAfterAuthorization from '../pages/ReturnAfterAuthorization';
import Faq from '../pages/Faq';
import NotFound from '../pages/NotFound';
import Discovery from '../pages/Discovery';
import Contact from '../pages/Contact';
import NotificationsPage from '../pages/NotificationsPage';
import SalesPage from '../pages/SalesPage';
import PurchasesPage from '../pages/PurchasesPage';
import Maintenance from "../pages/Maintenance";


export default function Routes() {
 const appMaintenance = process.env.REACT_APP_MAINTENANCE
 if(appMaintenance === 'true'){
   return(
     <Router>
       <Switch>
         <Route path='/' component={Maintenance} />
       </Switch>
     </Router>
     )
 }else{
   return (
     <Router>
       <Switch>
         <Route path='/' exact component={Home} />
         <Route path='/photos/:photoId' component={PhotoPage} />
         <Route path='/registrar' component={Signup} />
         <Route path='/usage' component={UsageTerms} />
         <Route path='/privacy' component={PrivacyTerms} />
         <Route path='/signin' component={Signin} />
         <Route path='/forgot-password' component={RequestNewPassword} />
         <Route path='/reset' component={ResetPasswordPage} />
         <Route path='/downloads' component={DownloadPage} />
         <Route path='/upload' component={UploadPage} />
         <Route path='/info' component={AdditionalUserInfo} />
         <Route path='/cart' component={Cart} />
         <Route path='/search' component={Search} />
         <Route path='/users/search' component={Search} />
         <Route path='/settings/:id' component={SettingsPage} />
         <Route path='/pro' component={BecomePro} />
         <Route path='/quem-somos' component={QuemSomos} />
         <Route path='/transacoes/vendas' component={SalesPage} />
         <Route path='/transacoes/compras' component={PurchasesPage} />
         <Route path='/autorizacao' component={ReturnAfterAuthorization} />
         <Route path='/faq' component={Faq} />
         <Route path='/checkout/:session' component={Checkout} />
         <Route path='/rooms/:id' component={Album} />
         <Route path='/users/:username' component={Photographer} />
         <Route path='/discovery' component={Discovery} />
         <Route path='/contact' component={Contact} />
         <Route path='/notifications' component={NotificationsPage} />
         <Route component={NotFound} />
       </Switch>
       <Switch>
       </Switch>
     </Router>
   );
 }
}
