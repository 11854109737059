import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
`;

export const Main = styled.main`
  width: 50%;
  padding: 200px 200px;
  min-height: 80%;
  box-sizing: border-box;
`;

export const ErrorMessage = styled.p`
  margin-top: -50px;
  margin-bottom: 50px;
  color: ${Colors.error};
  font-size: 14px;
  position: absolute;
`;

export const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 80px;

  img {
    width: 100px;

    @media (max-width: 800px) {
      width: 70px;
    }
  }
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;
  gap: 30px;

  @media (max-width: 600px) {
    flex-direction: column;
    text-align: center;
    gap: 20px;
  }
`;

export const Text = styled.p`
  margin-bottom: 60px;
  color: ${Colors.fontDefault};
  font-weight: bold;
  /* font-size: 20px; */
`;
