import styled from 'styled-components';
import React from 'react';
import Colors from '../../styles/Colors';

const H1 = styled.h1`
  font-family: 'Merriweather', serif;
  font-size: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: ${Colors.fontDefault};
`;

export default function Title(props) {
  return <H1>{props.text}</H1>;
}
