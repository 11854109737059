import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const WrapperSellTax = styled.div`
    border-bottom: 5px solid ${(props => props.borderColor)};
    width: 90%;
`

export const Row = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 80px;
    padding-right: 20px;
    height: 30px;

    @media (max-width: 800px){
        padding-left:0;
    }
`

export const Title = styled.p`
    font-size: 14px;
    font-weight: bold;
`

export const Value = styled.p`
    font-size: 14px;
`

export const Warning = styled.p`
    font-size: 13px;
`