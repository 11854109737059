import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.button`
  height: 40px;
  min-width: 110px;
  padding: 0 40px;
  box-sizing: border-box;
  background-color: ${(props) => props.backgroundColor};
  border: ${(props) => props.borderWidth}px solid
    ${(props) => props.borderColor};
  border-radius: 5px;
  cursor: pointer;
  text-transform: uppercase;
  color: ${(props) => (props.blue ? 'white' : Colors.fontDefault)};
  font-weight: 500;

  &:hover {
    opacity: 0.6;
  }
`;
