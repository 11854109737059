import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const LogoWrapper = styled.div`
  width: 100%;
  text-align: center;
  margin-bottom: 80px;

  img {
    width: 70px;

    @media (max-width: 800px) {
      width: 70px;
    }
  }
`;

export const Form = styled.form`
  position: relative;
`;

export const ForgotPassword = styled.div`
  margin-top: -30px;
  font-size: 14px;
  margin-bottom: 100px;
`;

export const BtnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 50px;
  margin-bottom: 30px;

  > button + a {
    margin-left: 10px;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    text-align: center;

    > button + a {
      margin-left: 0;
      margin-top: 10px;
    }
  }
`;

export const ReturnBtn = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;

  a {
    color: ${Colors.fontDefault};
    text-decoration: none;
  }

  a:visited {
    color: ${Colors.fontDefault};
  }
`;

export const ErrorWrapper = styled.div`
  background-color: ${Colors.error};
  margin-top: -50px;
  margin-bottom: 20px;
  color: white;
  padding: 10px 5px;
  font-size: 13px;
`;
