import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 93%;
  margin-bottom: 30px;

  > a{
    cursor: pointer;
    display: block;
  }

  img {
    max-width: 100%;
    cursor: pointer;
    pointer-events: none;
  }

  @media (max-width: 1500px){
    width: 96%;
  }


  @media (max-width: 640px){
    width: 100%;
  }

  @media (max-width: 550px ){
    width: 96%;
  }

  @media (max-width: 490px){
    width: 100%;
  }
  
`;
