import styled from "styled-components";
import Colors from "../../styles/Colors";

export const SectionTitle = styled.p`
    text-align: center;
    font-family: 'Merriweather',serif;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    color: ${Colors.fontDefault};

    @media (max-width: 850px){
        margin-top: 40px;
    }
`;

export const PhotoWrapper = styled.div`
    background-color: ${Colors.BlackPhotoDisplay};
    margin: 30px 0 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px;

    img{
        max-width: calc(100% - 30px);
        max-height: 600px;
    }

`;

export const PhotoBar = styled.div`
    background-color: 
        ${(props) => props.sale ? Colors.blueDefault : Colors.goldDefault};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px;
    margin-bottom: 80px;

    a{
        text-align:center;
        text-decoration: none;
        color: inherit;
    }

    a:visited:{
        color: inherit;
    }

    a:hover{
        opacity: 0.8;
    }
`;


export const Title = styled.p`
    font-size: 18px;
    font-family: 'Merriweather', serif;
    margin-bottom: 10px;
`;

export const Description = styled.p`
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    margin-bottom: 30px;`
;

export const UserInfo = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;

    > p{
        margin-left: 15px;
        font-size: 14px;
    }
`;