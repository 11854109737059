import React, { useState } from 'react';

export const CartItemContext = React.createContext({});

export const CartItemProvider = ({ children }) => {
  const [cartItem, addCartItem] = useState([]);
  const [cartNotEmpty, setCartNotEmpty] = useState(false);

  return (
    <CartItemContext.Provider
      value={{ cartItem, addCartItem, cartNotEmpty, setCartNotEmpty }}
    >
      {children}
    </CartItemContext.Provider>
  );
};
