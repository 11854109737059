import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const BaseMain = styled.main`
    width: 70%;
    margin: 0 auto;
    padding: 90px 0 20px;

    @media (max-width: 1650px){
        width: 85%;
    }

    @media (max-width: 1240px){
        width:95%;
    }
    
    @media(max-width: 850px){
    padding: 40px 0 20px;
    }

    @media (max-width: 550px) {
    width: 90%;
}
`   

export const InfoWrapper = styled.div`
    display: flex;
    justify-content: space-around;

    @media (max-width: 1100px){
        flex-direction: column-reverse;
    }
`;

export const ResumeWrapper = styled.div`
    width: 30%;
    background-color: ${Colors.goldDefault};
    height: 100%;
    padding: 60px;

    h2{
        margin-bottom: 40px;
    }

    @media (max-width: 1100px){
        width: 100%;
        margin-bottom: 40px;
    }

`;

export const DataWrapper = styled.div`
    padding: 60px;
    width: 70%;

    @media (max-width: 1380px){
        padding:20px;
    }

    @media (max-width: 1100px){
        width: 100%;
    }
`;

export const SectionTitle = styled.h2`
    font-family: 'Merriweather', sans-serif;
    font-size: 22px;
    color: ${Colors.fontDefault};
`

export const ProductsWrapper = styled.div`
    border-bottom:  1px solid ${Colors.fontDefault};

    
`;

export const Product = styled.div`

    font-family: 'Montserrat', sans-serif;
    color: ${Colors.fontDefault};
    font-size: 16px;
    margin: 40px 0;
    
    
    span{
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        font-size: 15px;
    }

`;

export const SubtotalWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 30px 0;
    border-bottom:  1px solid ${Colors.fontDefault};

    span{
        display: flex;
        justify-content: space-between;
        width: 100%;
        
    }

    span + span{
        margin-top: 20px;
    }


    p{
        font-family: 'Montserrat', sans-serif;
        color: ${Colors.fontDefault};
        font-size: 16px;
        font-weight: bold;
    }

`;


export const PoweredBy = styled.div`

    margin-top: 30px;
    text-align: right;

    p{
        font-size: 13px;
    }

    img{
        width: 150px;
    }

`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
`

export const StatusWrapper = styled.div`
    display: flex;

    div + div{
        margin-left: 10px;
    }
`

export const ButtonSection = styled.div`
    padding: 10px;
    background-color: ${Colors.goldDefault};
    border-radius: 5px;
    cursor: pointer;

    svg{
        color: ${Colors.fontDefault};
    }
`

export const FormDobleFile = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 30px;

    span{
        width: 45%;
    }

    @media (max-width: 1100px){
        flex-direction: column-reverse;
    }


    
`
export const WrapperCardData = styled.div`
    display: flex;
    justify-content: space-between;

    span{
        width: 45%;
    }

    @media (max-width: 550px){
        flex-direction: column;

        span{
            width: 100%;
        }
    }

`

export const CepHolder = styled.div`
    display: flex;

    button{
        height: 51px;
        border: none;
        background-color: ${Colors.goldDefault};
        border-radius: 5px;
        padding: 0 30px;
    }

    > p{
        margin-left: 30px;
        color: ${Colors.error};
        font-size: 15px;
        margin-top: 20px;
    }
`

export const CardInfoWrapper = styled.div`
    margin-right: 60px;
    
    @media (max-width: 1100px){
        margin-top: 40px;
        margin-right: 0;
    }

`

export const CheckboxDataWrapper = styled.div`
    display: flex;
    align-items: center;
    
    p{
        font-size: 15px;
        color: ${Colors.fontDefault};
    }
`
export const LoadingWrapper = styled.div`
text-align: center;
`

export const ProResume = styled.div`
    p{
        font-size: 15px;
        color: ${Colors.fontDefault};
    }
`

export const SuccessPageWrapper = styled.div`

    margin-top: 70px;

    span{

        
        >p{
            display: inline-block;
            padding: 10px 40px;
            border-bottom: 3px solid ${Colors.error};
            font-size: 18px;
            font-weight: bold;
            margin-bottom:50px;
            color: ${Colors.fontDefault};
        }
    }

    svg{
        margin-bottom: 50px;
    }

    p{
        margin-bottom: 10px;
    }

    a{
        display: inline-block;
        background-color: ${Colors.goldDefault};
        width: auto;
        padding: 15px 40px;
        margin: 50px auto 0;
        font-size: 14px;
        color: ${Colors.fontDefault};
        text-decoration: none;
        border-radius: 5px;

        &:hover{
            opacity: 0.8;
        }
    }

    p > a{
        background-color: white;
        display: initial;
        padding:0;
        // font-style: italic;
        font-weight: bold;
        font-size: 16px;
        
        
    }
`

export const ErrorMessageToContinue = styled.div`
    background-color: ${Colors.error};
    color: white;
    padding: 10px;
    text-align: center;
    font-size: 14px;
`