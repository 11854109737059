import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const BaseContainer = styled.div`
  width: 100%;
  min-height: calc(100vh - 288px);
  // background-color: ${Colors.grayDefault};
  margin-top: 40px;

  @media (max-width: 850px){
    margin-top: 0;
  }
  

`;
