import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const ContentWrapper = styled.div`
  margin-top: 70px;

  > form {
    margin-top: 70px;
    margin-bottom: 100px;
    width: 60%;

    @media (max-width: 1130px) {
      width: 100%;
    }

    > div:first-of-type {
      display: flex;
      gap: 40px;
      justify-content: flex-start;

      @media (max-width: 800px) {
        gap: 20px;

        > span > input {
          margin-bottom: 20px;
        }
      }

      span:first-child {
        width: 80%;
        display: flex;
        flex-direction: column;

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      span:nth-child(2) {
        width: 20%;

        @media (max-width: 800px) {
          width: 100%;
        }
      }

      @media (max-width: 800px) {
        flex-direction: column;
      }
    }
  }
`;

export const Label = styled.label`
  font-size: 18px;
  color: ${Colors.fontDefault};
  font-weight: bold;
`;

export const SocialWrapper = styled.div`
  margin-bottom: 70px;

  @media (max-width: 800px) {
    margin-top: 70px;
  }
`;

export const Social = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding-left: 10px;

  img {
    width: 30px;
  }

  input {
    border: none;
    width: 50%;
    border-bottom: 2px solid ${Colors.goldDefault};
    font-size: 18px;
    background-color: transparent;

    @media (max-width: 1130px) {
      width: 100%;
    }

    &::placeholder {
      font-style: italic;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const FieldCheckbox = styled.div`
  margin-top: 50px;
  margin-bottom: 70px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  div {
    width: 30%;

    p {
      margin-right: auto;
      margin-left: 10px;
      font-size: 16px;
    }
  }

  @media (max-width: 800px) {
    flex-direction: initial;
    gap: 50px;
  }

  @media (max-width: 500px) {
    flex-direction: initial;
    gap: 30px;

    div {
      width: 40%;
    }
  }

  @media (max-width: 450px) {
    flex-direction: initial;
    gap: 30px;

    div {
      width: 50%;
    }
  }
`;

export const TypeCheckbox = styled.div`
  display: flex;
  margin-top: 50px;
  gap: 30px;

  @media (max-width: 1130px) {
    flex-direction: column;
  }

  p {
    margin-right: auto;
    margin-left: 10px;
    font-size: 16px;
  }
`;

export const Main = styled.main`
  width: 70%;
  margin: 0 auto;
  padding: 60px 0 20px;
  position: relative;

  @media (max-width: 550px) {
    width: 90%;
  }
`;

export const Avatar = styled.div`
  position: absolute;
  right: 100px;
  text-align: center;

  img {
    width: 100%;
  }

  button {
    margin-top: 10px;
    background-color: ${Colors.redDefault};
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 30px;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }

  @media (max-width: 1540px) {
    > div {
      width: 200px;
      height: 200px;
    }
  }

  @media (max-width: 1130px) {
    margin-top: 100px;
    position: initial;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (max-width: 550px) {
    margin-top: 50px;
  }
`;

export const LoadingWrapper = styled.div`
  text-align: center;
  margin-top: 70px;
`;

export const ResponseWrapper = styled.div`
  background-color: ${(props) => (props.error ? Colors.error : Colors.success)};
  padding: 30px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

  p {
    color: white;
    font-weight: bold;
  }

  svg {
    cursor: pointer;
    color: white;

    &:hover {
      transform: scale(1.3);
    }
  }
`;

export const DeleteWrapper = styled.div`
  margin-top: 70px;

  p {
    margin-bottom: 20px;
    line-height: 22px;
  }


  div {
    display: flex;
    align-items: center;

    > p {
      margin-bottom: 0;
      cursor: pointer;
      margin-left: 20px;
    }

    @media (max-width: 550px){
      flex-direction:column;

      > p{
        margin-left: 0;
        margin-top: 20px;
      }
    }

  }

  a{
    color: ${Colors.fontDarker}
  }
`;

export const DeleteButton = styled.button`
  border: ${(props) =>
    props.highlight ? 'none' : `1px solid ${Colors.error}`};
  background-color: ${(props) =>
    props.highlight ? `${Colors.error}` : 'transparent'};
  color: ${(props) => (props.highlight ? 'white' : `${Colors.fontDefault}`)};
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.7;
  }
`;

export const LabelTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${Colors.fontDefault};
  margin-bottom: 20px;
`;
