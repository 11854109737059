import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const SecundaryButton = styled.div`
  padding: 5px 10px;
  display: flex;
  align-items: center;
  height: 34;
  border-radius: 5px;
  font-weight: bold;
  font-size: 14px;
  background-color: ${(props) =>
    props.savingBtn ? Colors.blueDefault : Colors.goldDefault};
  color: ${(props) => (props.savingBtn ? 'white' : Colors.fontDefault)};
  cursor: pointer;

  &:hover {
    opacity: 0.6;
  }

  svg {
    margin-right: 8px;
  }
`;
