import styled from "styled-components";
import Colors from "../../styles/Colors";

export const ContentWrapper = styled.main`
    text-align: center;
    
    p+p{
        margin-top: 16px;
    }

    a{
        margin-top: 30px;
        display: block;
        text-decoration: none;
        color: ${Colors.fontDefault};

        &:hover{
            opacity: 0.8;
            font-size: 17px;
        }
    }
`

export const Title = styled.h1`
    font-family: 'Merriweather', serif;
    margin-top: 30px;
    font-size: 18px;
    color: ${Colors.fontDefault};
    margin-bottom: 60px;
`

