import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.li`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px 50px;
  border-bottom: 4px solid ${Colors.blueDefault};
  list-style: none;
  position: relative;
  box-sizing: border-box;

  @media (max-width: 1300px) {
    padding: 20px;

    > :nth-child(3) {
      display: none;
    }
  }

  @media (max-width: 900px) {
    padding: 20px 0;
    border: none;
    height: 50px;

    > :nth-child(1) {
      position: absolute;
      top: 20px;
      left: 0;
      height: 100px;
    }

    > :nth-child(2) {
      position: absolute;
      top: 20px;
      width: 70%;
    }

    > :nth-child(4) {
      padding: 20px;
    }

    > :nth-child(5) {
      position: absolute;
      bottom: -20px;
      width: 100%;
      text-align: center;

      > p {
        font-size: 20px;
        text-align: center;
      }
    }
  }
`;

export const Content = styled.div`
  width: 25%;
  text-align: center;
  color: ${Colors.fontDefault};

  a {
    text-decoration: none;
    color: inherit;
  }

  a:visited {
    color: inherit;
  }

  a:hover {
    opacity: 0.6;
  }

  p + p {
    margin-top: 10px;
  }

  img {
    max-width: 50%;
    max-height: 50%;
  }

  &:last-child p {
    text-align: right;
  }

  &:first-child {
    text-align: initial;
  }
`;

export const ImportantText = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  /* text-align: right; */

  @media (max-width: 1220px) {
    text-align: left;
    font-size: 18px;
  }

  @media (max-width: 900px) {
    text-align: center;
    font-size: 15px;
  }
`;

export const Licence = styled.div`
  background-color: ${(props) => props.background};
  width: 200px;
  padding: 20px 40px;
  border-radius: 8px;
  display: inline-block;
  color: white;
  font-weight: bold;
  text-align: center;

  @media (max-width: 1300px) {
    width: auto;
    display: inline-block;
    padding: 20px 5px;
  }

  @media (max-width: 900px) {
    position: absolute;
    top: 150%;
    left: 0;
    width: 100%;
    padding: 5px;
    border-radius: 2px;
  }
`;

export const CloseButton = styled.div`
  cursor: pointer;

  @media (max-width: 900px) {
    position: absolute;
    top: 20px;
    right: 0;
  }
`;
