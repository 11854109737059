import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.main`
  width: 60%;
  margin: 0 auto;
  margin-top: 30px;
  padding-bottom: 30px;

  a,  a:visited{
    font-size: 16px;
    margin-bottom: 20px;
    color: ${Colors.fontDefault};
    text-decoration: none;
    display: block;
    margin-bottom: 60px;
  }

  h1 {
    margin-bottom: 30px;
  }

  @media (max-width: 900px) {
    width: 90%;
  }

  @media (max-width: 450px) {
    width: 95%;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

export const TagWrapper = styled.div`
  display: flex;
  /* gap: 10px; */
  margin-top: -30px;
  margin-bottom: 50px;
  flex-wrap: wrap;

  > div {
    margin-top: 10px;
  }

  > div + div {
    margin-left: 10px;
  }
`;

export const DeleteWrapper = styled.div`
  margin-top: 70px;

  p {
    margin-bottom: 20px;
  }

  div {
    display: flex;
    align-items: center;
    gap: 30px;

    > p {
      margin-bottom: 0;
      cursor: pointer;
    }
  }
`;

export const DeleteButton = styled.button`
  border: ${(props) =>
    props.highlight ? 'none' : `1px solid ${Colors.error}`};
  background-color: ${(props) =>
    props.highlight ? `${Colors.error}` : 'transparent'};
  color: ${(props) => (props.highlight ? 'white' : `${Colors.fontDefault}`)};
  padding: 10px 20px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    opacity: 0.7;
  }
`;

export const Label = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${Colors.fontDefault};
  margin-bottom: 20px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  /* gap: 10px; */
  margin-bottom: 50px;

  > label + p {
    margin-left: 10px;
  }

  p {
    font-weight: bold;
    color: ${Colors.fontDefault};
  }

  
`;
