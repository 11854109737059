import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const WrapperBanner = styled.div`
    margin-top: 40px;
    width: 100%;
    background: ${Colors.goldDefault};
    background: linear-gradient(101.89deg, ${Colors.blueDefault} 8.33%, ${Colors.goldDefault} 80.68%);
    // min-height: 390px;
    margin-bottom: -40px;
    // padding: 40px;
    text-align:center;

    @media (max-width: 850px){
        margin-top: 0;
    }

    img{
        width: 100%;

    }
`

export const IconWrapper = styled.div`
    display: flex;
    width: 60%;
    flex-wrap: wrap;
    margin: 30px auto 0;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 900px){
        width: 90%;
    }

    @media (max-width: 500px){
        // flex-direction: column;
        width: 80%;
    }


`

export const Icon = styled.div`
    p{
        margin-top: 20px;
        font-size: 20px;
        font-family: 'Merriweather', serif;
        color: ${Colors.fontDarker};
    }

    svg{
        color: ${Colors.fontDarker}
    }

    @media (max-width: 600px){
        p{
            font-size:15px;
        }  
    }

    @media (max-width: 600px){
       margin: 40px 15px 0 15px;  
    }


`

export const TextWrapper = styled.div`
    margin-top: 60px;
    padding-bottom: 30px;

    p{
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        color: ${Colors.fontDarker};
        line-height: 24px;
        
    }

    p + p{
        margin-top: 20px;
    }


`