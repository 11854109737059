import styled from 'styled-components'

export const Wrapper = styled.div`

    p{
        line-height: 26px;
        text-align: justify;
    }

    p + p{
        margin-top: 26px;
    }

`