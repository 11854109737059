import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
    background-color: ${Colors.goldDefault};        
    position: fixed;
    width: 100%;
    z-index: 2;
    height: 40px;
    display: flex;
    align-items: center;



    div{
        display: flex;
        align-itens: center;
        justify-content: space-between;
        width: 50%;
        padding: 0 100px;
        margin: 0 auto;
    }

    a{
        display: block;
        height: 14px;
        width: 100px;
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        text-decoration: none;
        text-align: center;
        color: ${Colors.fontDefault};
    }
    
    a:hover{
        opacity: 0.8;
    }
    
   
    @media (max-width: 1550px){
        div{
            width: 80%;
        }
    }

    @media (max-width: 1150px){
        div{
            width: 95%;
        }
    }

    @media(max-width: 850px){
        display: none;

    }

`