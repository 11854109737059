import React from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import { BaseMain } from '../../containers/BaseMain';
import Title from '../../components/Title';
import { Helmet } from 'react-helmet';
import Colors from '../../styles/Colors';
import Footer from '../../components/Footer';

const UsageTermsWrapper = styled.div`
  margin-top: 40px;

  p {
    text-align: justify;
    line-height: 26px;
    font-size: 15px;

    bold{
      font-weight: bold;
      color: ${Colors.fontDefault};
    }
  }
  
`;

const TitleSection = styled.h2`
    text-align: justify;
    line-height: 26px;
    font-size: 20px;
    text-transform: uppercase;
    font-family: 'Merriweather',serif;
    color: ${Colors.fontDefault};
    margin: 40px 0 20px;
`;

export default function UsageTerms() {
  return (
    <>
      <Helmet>
        <title>Termos de Uso | ViAssim</title>
      </Helmet>
      <Header />
      <BaseMain>
        <Title text='Termos de uso' />
        
        <UsageTermsWrapper>
          <p>Última Atualização: agosto de 2020</p>
          <br></br>
          <p>Bem-vindo(a)! Obrigado por Utilizar a plataforma ViAssim!</p>
          <br></br>
          <br></br>
          <p>
          Esta aplicação e seu conteúdo (“Plataforma VIASSIM") foram desenvolvidos e são controlados e operados pela ViAssim Fotografia Ltda., sociedade empresária limitada, com sede na Rua Percilio Neto, n. 167, sala 82-A, CEP 04.131-080, Vila Gumercindo, São Paulo/SP, inscrita no CNPJ/ME sob o n. 39.718.216/0001-22. Todos os direitos reservados.
          </p>
          <br></br>
          <p>
          Estes termos de uso têm por objeto definir as regras a serem seguidas para a utilização da Plataforma Viassim (“Termos de Uso“), por você(“Usuário”), sem prejuízo da aplicação da legislação vigente.
          </p>
          <br></br>
          <p>
          AO UTILIZAR A PLATAFORMA “VIASSIM”, VOCÊ AUTOMATICAMENTE CONCORDA COM ESTES TERMOS DE USO, RESPONSABILIZANDO-SE INTEGRALMENTE POR TODOS E QUAISQUER ATOS PRATICADOS POR VOCÊ NA PLATAFORMA VIASSIM. CASO VOCÊ NÃO CONCORDE COM QUALQUER DOS TERMOS E CONDIÇÕES ABAIXO ESTABELECIDOS, VOCÊ NÃO DEVE UTILIZAR A PLATAFORMA VIASSIM. 
          </p>
          <br></br>
          <TitleSection>1. O que é a plataforma viassim?</TitleSection>
          <p>
          1.1. A Plataforma Viassim é uma MarketPlace que funciona mediante conexão à internet e que tem como objetivo intermediar a venda e compra de Obras Fotográficas que serão disponibilizadas na plataformapelo Usuário e oferecidas à venda aos Compradores interessados pelas mesmas (“Serviços”) da seguinte forma:
          </p>
          <br></br>
          <p>
          (i) criação de um perfil de Usuário para Fotógrafos Profissionais ou Amadores com o objetivo de postar as Obras Fotográficas de sua autoria e vender as mesmas aos compradores que se interessem;
          </p>
          <br></br>
          <p>
          (ii) O Usuário, após realização de um cadastro simples:
          </p>
          <br></br>
          <p>
          a. Poderá efetivar upload ilimitado de obras fotográficas DE SUA PRÓPRIA AUTORIA;
          </p>
          <br></br>
          <p>
          b. Possibilidade de manter disponível até 10 obras fotográficas para venda;
          </p>
          <br></br>
          <p>
          c. A realização do cadastro gera automaticamente a criação de uma “loja” dentro do marketplace da Plataforma Viassim;
          </p>
          <br></br>
          <p>
          d. Possibilidades de usuários seguirem usuários;
          </p>
          <br></br>
          <p>
          e. Possibilidade de usuários fazerem uploads de fotos;
          </p>
          <br></br>
          <p>
          f. Possibilidade de usuários fazerem download fotos atrelados a sistema de pagamento, através de link a ser encaminhado ao e-mail do usuário;
          </p>
          <br></br>
          <p>
          (iii) Ao realizar o uploud das fotos, o Usuário deverá colocar em cada uma breve legenda (quem? Onde? Como? Por quê?). Também será incluído pelo Usuário as palavras-chaves para que a obra fotográfica seja encontradanos mecanismos de busca da plataforma Viassim.
          </p>
          <br></br>
          <p>
          (iv) As obras fotográficas pretendidas à venda pelo usuário e devidamente cadastradas na plataforma Viassim pelo Usuário deverão conter a seu lado o preço para venda da mesma. <bold>O VALOR DE VENDA DE CADA OBRA FOTOGRÁFICA SERÁ DETERMINADO À CRITÉRIO DO USUÁRIO, ATRIBUINDO VALORES PARA AS MODALIDADES EDITORIAL, COMERCIAL E FOTO IMPRESSA, tendo em vista que cada Usuário poderá aferir o valor e modalidade de uso de sua obra fotográfica; - SOMENTE AS FOTOS COLOCADAS A VENDA DEVERÃO TER VALOR. AS DEMAIS PODERÃO SUBIR SEM VALOR.</bold>
          </p>
   
          <br></br>
          <p>
          (v) O Comprador da obra fotográfica, após escolher a foto que lhe interessa, será direcionado a um campo onde informará qual o uso pretendido da obra fotográfica.
          </p>

          <br></br>
          <p>
          (vi) <bold><u> O Comprador da Obra Fotográfica segue para check out, onde pagará por cartões de crédito; A Plataforma Viassim acrescentará ao valor da obra fotográfica o percentual de comissionamento pela venda. </u> </bold>
          </p>

          <br></br>
          <p>
          (vii) O Usuário fará a gestão de seu próprio cadastro de fotos e venda de maneira personalizada, conforme seus interesses e necessidades, a partir da inclusão de obras fotográficas de sua própria autoria na Plataforma Viassim, de acordo com as áreas e assuntos variados escolhidos pelo próprio Usuário;
          </p>

          <br></br>
          <p>
          1.2. A Viassim se reserva o direito de suspender ou cancelar, a qualquer momento, o acesso do Usuário à Plataforma Viassim em caso de suspeita de <bold> USO DE OBRA FOTOGRÁFICA PERTENCENTES À TERCEIROS E QUE SEJAM POSTADAS À VENDA NA PLATAFORMA VIASSIM COMO PERTENCENTES AO USUÁRIO CADASTRADO</bold>, fraude, uso indevido de direito autoral, uso indevido de propriedade industrial e intelectual, obtenção de benefício ou vantagem de forma ilícita, ou pelo não cumprimento de quaisquer condições previstas nestes Termos de Uso ou na legislação aplicável. Nesses casos, não será devida qualquer indenização ao Usuário, e a Viassim poderá promover a competente ação de regresso, se necessário, bem como tomar quaisquer outras medidas necessárias para perseguir e resguardar seus interesses. Em caso de cancelamento do acesso do Usuário à Plataforma Viassim com base no aqui disposto, as regras da cláusula 3.9 destes Termos de Uso serão aplicáveis.
          </p>
          
          <TitleSection>2. Como acesso a plataforma ViAssim?</TitleSection>
          <p>
          2.1. Para acessar a Plataforma Viassim e utilizar suas funcionalidades, é necessário efetuar um cadastro. Para cadastrar-se, o Usuário deverá voluntariamente fornecer informações pessoais, conforme solicitado, garantindo que elas sejam corretas, completas e verdadeiras, comprometendo-se a manter seus dados sempre atualizados. Caso a ViAssim detecte alguma conta feita a partir de informações falsas, por Usuários que, por exemplo, não possuem a idade mínima permitida ou por sociedade irregulares ou inexistentes, essa conta será automaticamente cancelada e estes Termos de Uso serão automaticamente rescindidos. Para saber mais sobre como tratamos a privacidade de suas informações pessoais, acesse nossa Política de Privacidade.
          </p>

          <br></br>
          <p>
          2.2. Alternativamente, podemos oferecer ao Usuário a possibilidade de realizar seu cadastro por meio de sua conta de serviços de plataformas terceiras, como Google e Facebook, mas não apenas estes. Nessa hipótese, o Usuário nos autoriza a acessar, armazenar e utilizar as informações fornecidas por terceiros a fim de criar a sua conta na Plataforma Viassim, especificamente o seu nome e e-mail. Nós NÃO temos qualquer ligação com esses terceiros e, portanto, não possuímos qualquer responsabilidade, tampouco garantimos, em qualquer hipótese, os produtos ou serviços fornecidos por eles.
          </p>          
          
          <br></br>
          <p>
          2.3. Para utilizar a Plataforma Viassim, o Usuário deve (i) ser maior de 18 (dezoito) anos e possuir plena capacidade legal para tanto; ou (ii) ser uma sociedade em situação regular, legalmente constituídas e existente de acordo com a legislação aplicável.
          </p>    

          <br></br>
          <p>
          2.3.1. Caso o Usuário esteja contratando os Serviços em nome de uma sociedade, o Usuário declara que detém todas as autorizações legais necessárias para fazer o cadastro, aceitar estes Termos de Uso, realizar qualquer contratação dos Serviços. A Viassim não será responsável pela falsidade da presente declaração, cabendo ao Usuário o dever de indenizar a Viassim e terceiros por todos os danos e prejuízos causados.
          </p>  

          <br></br>
          <p>
          2.4. A partir do cadastro, o Usuário será titular de uma conta que somente poderá ser acessada pelo Usuário, mediante login e senha. O Usuário concorda que irá manter o seu login e senha em sigilo e seguro, fora do alcance de terceiros, e não permitirá que a sua conta na Plataforma Viassim seja usada por outras pessoas. Dessa forma, o Usuário responsabiliza-se por todas as ações realizadas em sua conta. Em caso de perda, extravio ou suspeita de utilização indevida de sua conta, login ou senha, a Viassim deverá ser imediatamente comunicada para que sejam tomadas as medidas cabíveis.
          </p>


          <TitleSection>3. Como funciona a assinatura e cancelamento do "Plano Pro"</TitleSection>
          <p>
          3.1. A Plataforma Viassim oferece o serviço de assinatura denominado “Assinatura Pro”. Este serviço oferecido pela Plataforma Viassim somente poderá ser contratado mediante a assinatura de plano anual de serviço pelo Usuário, <bold><u>PELO PERÍODO MÍNIMO DE 12 MESES</u></bold>.
          </p>

          <br></br>
          <p>
          3.2. Quando da contratação da “Assinatura Pro”, a respectiva subscrição irá requerer o pagamento antecipado para que o acesso às funcionalidades cobertas por tal plano seja liberado. Dessa forma, o Usuário terá acesso à Plataforma Viassim como “Usuário Pro” a partir da data em que o Usuário receber a confirmação de pagamento, durante todo o período correspondente aos 12 meses.
          </p>          
          
          <br></br>
          <p>
          3.2.a: <bold><u>O Usuário concorda que o plano será renovados automaticamente, a não ser que o Usuário manifeste-se contrariamente requerendo o cancelamento, antes da data de renovação</u></bold>
          </p>    

          <br></br>
          <p>
          3.3. O “Usuário Pro” terá como benefício uma página com espaço ilimitado e com opções avançadas de customização da página e ainda:
          </p>  

          <br></br>
          <p>
          a. separação de fotos por salas;
          </p>

           <br></br>
          <p>
          b. troca de cor de fundo da página;
          </p>  

           <br></br>
          <p>
          c. Uma leitura de portfólio feita por um profissional;
          </p>  

           <br></br>
          <p>
          d. Uploads e vendas de obras fotográficas ilimitados;
          </p>  

           <br></br>
          <p>
          e. Customização visual da loja;
          </p>  

           <br></br>
          <p>
          f. Possibilidade de criar álbuns temáticos;
          </p>  

           <br></br>
          <p>
          g. Receber e-books com dicas de marketing, divulgação e promoção visando aumentar vendas;
          </p>  

           <br></br>
          <p>
          h. Ter a possibilidade de aparecer na primeira página do site e ter foto em destaque como “Foto do Dia”;
          </p>  

           <br></br>
          <p>
          i. Possibilidade de saber quem viu seu perfil e quantas vezes;
          </p>  

           <br></br>
          <p>
          j. Sistema de cadastro de fotos, onde se coloca legenda das fotos e palavras-chave de busca;
          </p>  

           <br></br>
          <p>
          l. Criação de opção de comprador receber a foto comprada impressa;
          </p>  

           <br></br>
          <p>
          m.  atrelar ao site um parceiro de estúdio de impressão que faça o trabalho de printing e entrega ao comprador;
          </p>  

          <br></br>
          <p>
          n. criação de sistema de ranking, onde fotógrafo que pagar (ou comprar fotos), possa aparecer primeiro na busca (ex. Google).
          </p>  

          <br></br>
          <p>
          3.4. O cancelamento da subscrição ao plano pode ser realizado a qualquer tempo pelo Usuário, após o período mínimo de 12 meses de contratação. 
          </p> 

          <br></br>
          <p>
          3.5. Quando o Usuário cancelar a subscrição do plano pago, ele não perderá sua conta nem o conteúdo ali armazenado, pois ele automaticamente estará aderindo ao plano gratuito, que está sujeito às ferramentas disponíveis e às limitações de tal plano.  
          </p> 

          <br></br>
          <p>
          3.6. Caso o Usuário deseje cancelar também o plano gratuito, deverá solicitar cancelamento total de seu cadastro através de ícone especifico para este fim, direto na página principal da Viassim. O Usuário está ciente de que a solicitação do cancelamento do cadastro é irreversível. Nesse caso, todas as informações de sua conta, histórico e demais informações relacionadas aos Serviços serão apagadas, não sendo possível recuperar qualquer dado perdido e o seu acesso à Plataforma Viassim. Em hipótese alguma a Viassim poderá ser responsabilizada por qualquer dano proveniente do cancelamento do cadastro feito pelo Usuário
          </p> 

          <TitleSection>4. Como são feitos os Pagamentos?</TitleSection>
          <p>
          4.1. Os pagamentos deverão ser realizados dentro da própria Plataforma Viassim, por meio de cartão de crédito. Entretanto, a ViAssim reserva-se no direito de alterar tanto os métodos de pagamento como as plataformas que oferecem o serviço de processamento das transações.
          </p>

          <br></br>
          <p>
          4.2. O preço pago pelo Usuário é final, não reembolsável e inclui todos os impostos e tributos referentes aos Serviços, a menos que diversamente determinado pela Viassim. A Viassim reserva-se o direito de estabelecer, remover e/ou revisar o preço relativo a todos os Serviços ou bens obtidos por meio do uso da Plataforma Viassim a qualquer momento. Nunca alteraremos o valor da sua subscrição sem avisá-lo com antecedência razoável
          </p>          
          
          <br></br>
          <p>
          4.3. O processamento das informações de pagamento e a confirmação do pagamento são realizados por sistemas de terceiros (ex. instituição financeira ou administradora do cartão de crédito), sendo a Plataforma Visssim uma mera interface entre o Usuário e esses sistemas.
          </p>    

          <TitleSection>5. Quais são os direitos da ViAssim sobre a plataforma ViAssim e seu conteúdo?</TitleSection>
          <p>
          5.1. Sujeito a estes Termos de Uso, a Viassim concede ao Usuário uma licença limitada, temporária, não exclusiva, não transferível, revogável e destinada a uso pessoal para usar a Plataforma Viassim somente para que o Usuário possa usufruir dos Serviços, de acordo com o plano contratado, e cumprir com as suas obrigações dispostas nestes Termos de Uso.
          </p>

          <br></br>
          <p>
          5.2.Todos os direitos relativos à Plataforma Viassim e suas funcionalidades são licenciados para ou de titularidade exclusiva da Viassim, inclusive no que diz respeito aos seus textos, imagens, layouts, software, códigos, bases de dados, gráficos, artigos, fotografias, contratos e demais conteúdos produzidos direta ou indiretamente pela Viassim. O Conteúdo da Viassim é protegido pela lei de direitos autorais e de propriedade intelectual. É proibido:
          </p>          
          
          <br></br>
          <p>
          (i) usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender ou explorar e fazer engenharia reversa do Conteúdo da Viassim, para qualquer finalidade, sem o consentimento prévio e expresso da Viassim;
          </p>

           <br></br>
          <p>
          (ii) vender, alugar, licenciar, sublicenciar, emprestar, caucionar ou transferir, de qualquer maneira que seja, a totalidade ou parte da licença de uso da Plataforma Viassim;
          </p>    

           <br></br>
          <p>
          (iii) efetuar modificações, acréscimos ou derivações em qualquer porção dos ativos que compõem o Conteúdo da Viassim;
          </p>    
          <br></br>
          <p>
          (iv) fazer engenharia reversa, descompilar ou desmontar a plataforma, ou qualquer outra medida que possibilite o acesso, ainda que parcial, ao código fonte da Plataforma Viassim;
          </p>    

           <br></br>
          <p>
          (v) remover, ocultar ou alterar quaisquer avisos de direitos autorais, marcas comerciais ou outros avisos de propriedade intelectual, legendas afixadas ou contidas em qualquer porção da Viassim;
          </p>    

           <br></br>
          <p>
          (vi) divulgar ou reproduzir a totalidade ou parte do Conteúdo da Viassim, salvo no que diz respeito à divulgação necessária aos seus empregados e funcionários que sejam Usuários e estejam envolvidos na exploração da Plataforma Viassim; ou
          </p>

          <br></br>
          <p>
          (vii) intervir na Plataforma Viassim ou manipulá-la para qualquer finalidade, ainda que para corrigir eventuais anomalias, sendo que a Viassim, pelo presente, reserva-se o direito exclusivo de correção de sua tecnologia.
          </p>

          <br></br>
          <p>
          5.2.1. Qualquer uso não autorizado do Conteúdo do Viassim será considerado como violação dos direitos autorais e de propriedade intelectual da Viassim.
          </p>

          <br></br>
          <p>
          5.2.2. A introdução, pelo Usuário, de quaisquer modificações ou alterações em qualquer porção da Plataforma Viassim, acarretará a exoneração da Viassim de qualquer responsabilidade decorrente destes Termos de Uso, podendo a Viassim, a seu exclusivo critério, cancelar o acesso do Usuário à plataforma Viassim sem a necessidade de qualquer notificação prévia.
          </p>

          <br></br>
          <p>
          5.3. A Viassim reserva-se o direito de, a seu critério e a qualquer tempo, alterar ou remover funcionalidades, bem como adicionar novas que tragam benefícios à utilização da Plataforma Viassim sem qualquer comunicação prévia ao Usuário e sem que lhe seja devida qualquer indenização.
          </p>  

          <br></br>
          <p>
          5.4. A Viassim poderá, a seu exclusivo critério, permitir que o Usuário apresente, carregue, publique ou disponibilize, na Plataforma Viassim, conteúdo ou informações de texto e imagem. O Usuário declara que é titular dos direitos autorias e dos direitos de propriedade intelectual do Conteúdo do Usuário e, se não o for, declara que possui autorização do respectivo titular para realizar todos os atos envolvendo o Conteúdo do Usuário relacionados aos Serviços e ao Uso da Plataforma Viassim, e, desde já, garante que não violará direitos autorais e de propriedade intelectual de terceiros.
          </p>  

          <br></br>
          <p>
          5.5. É proibido qualquer Conteúdo do Usuário que tenha caráter difamatório, calunioso, injurioso, violento, pornográfico, obsceno, ofensivo ou ilícito, conforme apuração da Viassim, a seu critério exclusivo. Também é proibido qualquer Conteúdo do Usuário que seja de propriedade exclusiva ou contenha informações relacionadas a outras pessoas ou empresas (ex. direito de autor, marcas), sem a expressa autorização do titular desses direitos, ou que de qualquer forma violem quaisquer direitos de terceiros. O Usuário é o exclusivo responsável pelo Conteúdo do Usuário e entende que em hipótese alguma a Viassim será responsável por violações do Usuário quanto ao disposto nesta cláusula. A Viassim poderá (mas não se obriga), a seu exclusivo critério, a qualquer momento, analisar, monitorar e remover qualquer Conteúdo do Usuário que viole as disposições estes Termos de Uso, sem que qualquer aviso seja enviado ao Usuário.
          </p>


          <TitleSection>6. Reclamações sobre violação de direito autoral</TitleSection>
          <p>
          6.1. Alegações de violação de direitos autorais relacionados à Plataforma Viassim ou a qualquer conteúdo nela disponível devem ser encaminhadas para o e-mail denuncia@viassim.com.br.
          </p>

          <TitleSection>7. Responsabilidades do Usuário e da ViAssim</TitleSection>
          <p>
          7.1. O Usuário é exclusivamente responsável pelo uso da Plataforma Viassim e deverá respeitar as regras destes Termos de Uso, bem como a legislação aplicável.
          </p>

          <br></br>
          <p>
          7.2. A Viassim, suas empresas controladoras, suas afiliadas, parceiras, seus administradores ou empregados não serão, em hipótese alguma, responsabilizados por danos diretos ou indiretos que resultem de, ou que tenham relação com o acesso, uso ou a incapacidade de acessar ou utilizar a Plataforma Viassim.
          </p>          
          
          <br></br>
          <p>
          7.3. TENDO EM VISTA AS CARACTERÍSTICAS INERENTES AO AMBIENTE DA INTERNET, A VIASSIM NÃO SE RESPONSABILIZA POR FALHAS DE ACESSO OU NAVEGAÇÃO NA PLATAFORMA VIASSIM DECORRENTES DE CIRCUNSTÂNCIAS ALHEIAS À SUA VONTADE E CONTROLE, TAIS COMO INTERRUPÇÕES OU SUSPENSÕES DE CONEXÃO, TRANSMISSÕES DE COMPUTADOR INCOMPLETAS OU QUE FALHEM, BEM COMO POR FALHA TÉCNICA DE QUALQUER TIPO, INCLUINDO, MAS NÃO SE LIMITANDO, AO MAU FUNCIONAMENTO ELETRÔNICO DE QUALQUER REDE, HARDWARE OU SOFTWARE, FALHAS NA INTERNET EM GERAL, QUEDAS DE ENERGIA, MAU FUNCIONAMENTO ELETRÔNICO E/OU FÍSICO DE QUALQUER REDE DE TELECOMUNICAÇÕES. A INDISPONIBILIDADE DE ACESSO À INTERNET OU À PLATAFORMA VIASSIM (INCLUINDO OS CASOS DE PARALISAÇÕES PROGRAMADAS PARA MANUTENÇÃO, ATUALIZAÇÃO E AJUSTES DE CONFIGURAÇÃO DA PLATAFORMA), BEM COMO QUALQUER FALHA HUMANA, TÉCNICA OU DE QUALQUER OUTRO TIPO, INCLUSIVE NO PROCESSAMENTO DAS INFORMAÇÕES, NÃO SERÃO CONSIDERADAS RESPONSABILIDADE DA VIASSIM. A VIASSIM SE EXIME DE QUALQUER RESPONSABILIDADE PROVENIENTE DE TAIS FATOS E/OU ATOS.
          </p>

           <br></br>
          <p>
          7.4. Quando permitido por lei, a Viassim e os seus fornecedores ou distribuidores não serão responsáveis por perda de lucros, perda de receita, perda de dados, perdas financeiras ou por danos indiretos e diretos. Na hipótese de a Viassim vir a ser obrigado a indenizar o Usuário, por qualquer razão, fica expressamente estipulado que, independentemente do motivo, o valor máximo de tal indenização será limitado ao valor equivalente a 12 (doze) meses da assinatura do plano contratado pelo Usuário.
          </p>    

           <br></br>
          <p>
          7.5. É deinteira responsabilidade do Usuário (i) equipar-se e responsabilizar-se pelos dispositivos de hardware (computador, celular, tablet, entre outros) necessários para o acesso à Plataforma Viassim, bem como pelo acesso desses dispositivos à Internet; (ii) manter o ambiente de seu dispositivo (computador, celular, tablet, entre outros) seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos; (iii) utilizar sistemas operacionais atualizados e eficientes para a plena utilização da Plataforma Viassim, de acordo com as especificações fornecidas pela Viassim; e (iv) proteger e manter em sigilo o seu login e senha de acesso à Plataforma Viassim, tendo ciência de que sua conta é de uso pessoal e intransferível, não devendo ser compartilhada com terceiros em qualquer circunstância.
          </p>    
          <br></br>
          <p>
          7.6. A Plataforma Viassim pode conter links para sites e aplicações de terceiros, assim como ter tecnologias a ela integradas, que não pertencem e não são controlados pela Viassim. Isso não implica, de maneira alguma, que a Viassim endossa, verifica, garante ou possui qualquer ligação com os proprietários desses sites ou aplicações, não sendo responsável pelo seu conteúdo, incluindo os produtos e serviços ali disponibilizados, sua precisão, políticas, práticas ou opiniões expressas em qualquer desses sites e aplicações de terceiros com os quais o Usuário interaja por meio da Plataforma Viassim. A Viassim recomenda que você leia os termos de uso e políticas de privacidade de cada site ou aplicação de terceiro que o Usuário vier a visitar ou utilizar.
          </p>    

          <TitleSection>8. O que mais eu preciso saber sobre estes termos de uso?</TitleSection>
          <p>
          8.1. A Plataforma Viassim está sempre sendo atualizada para melhorar a sua experiência. Por esse motivo, estes Termos de Uso podem ser alterados, a qualquer tempo, a fim de refletir os ajustes realizados. No entanto, sempre que ocorrer qualquer modificação, o Usuário será previamente informado pelo endereço de e-mail fornecidono momento do cadastro ou por um aviso em destaque na própria Plataforma Viassim. Caso você não concorde com os novos Termos de Uso, você poderá rejeitá-los, mas, infelizmente, isso significa que o Usuário não poderá mais ter acesso e fazer uso da Plataforma Viassim. Se de qualquer maneira você utilizar a Plataforma Viassim mesmo após a alteração destes Termos de Uso, isso significa que você concorda com todas as modificações realizadas. Em caso de conflitos, a última versão publicada dos Termos de Uso sempre prevalecerá às anteriores.
          </p>

          <br></br>
          <p>
          8.2. Estes Termos de Uso não criam qualquer outra modalidade de vínculo entre os Usuários e a Viassim, inclusive, sem limitação, sociedade, joint-venture, mandato, representação, parceria, consórcio, associação, formação de grupo econômico, vínculo empregatício ou similar. A Viassim permanecerá uma entidade independente e autônoma.
          </p>          
          
          <br></br>
          <p>
          8.3. A Viassim poderá ceder os direitos e obrigações referentes a estes Termos de Uso a empresas de seu mesmo grupo econômico ou societário, sem que seja devida qualquer comunicação ao Usuário.
          </p>

           <br></br>
          <p>
          8.4. A omissão ou tolerância da Viassim em exigir o estrito cumprimento dos termos e condições aqui definidos não constituirá, em nenhuma hipótese, em novação ou renúncia, nem impedirá que a Viassim cobre esses direitos do Usuário a qualquer tempo.
          </p>    

           <br></br>
          <p>
          8.5. Estes Termos de Uso são regidos pelas leis da República Federativa do Brasil. Quaisquer dúvidas e situações não previstas nestes Termos de Uso serão primeiramente resolvidas pela Viassim, caso persistam, deverão ser solucionadas pelo Foro da Comarca de São Paulo, Estado de São Paulo, com exclusão de qualquer outro, por mais privilegiado que seja ou venha a ser.
          </p>    
          <br></br>
          <p>
          8.6. Caso Você tenha alguma dúvida, comentário ou sugestão, por favor, entre em contato conosco por meio do e-mail viassim@viassim.com.br.
          </p>              

        </UsageTermsWrapper>
      </BaseMain>
      <Footer />
    </>
  );
}
