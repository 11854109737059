import styled from 'styled-components';
import Colors from '../../styles/Colors';

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 50px;

  
`;

export const UploadField = styled.div`
  width: 100%;
  height: 100px;
  border: 1px dashed black;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;

  div {
    width: 100%;
    padding: 14px;
  }

  &:hover {
    opacity: 0.5;
  }
`;

export const UploadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin-bottom: 50px;

  svg {
    margin-bottom: 10px;
  }

  img {
    max-width: 100%;
    max-height: 500px;
  }
`;

export const Form = styled.form`
  padding-bottom: 50px;

  > a, > a:visited{
    font-size: 16px;
    margin-bottom: 20px;
    color: ${Colors.fontDefault};
    text-decoration: none;
    display: block;
    margin-bottom: 60px;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  margin-top: -30px;
  margin-bottom: 50px;
`;

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 50px;

  p {
    font-weight: bold;
    color: ${Colors.fontDefault};
  }
`;

export const BtnWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 20px;

  @media (max-width: 500px) {
    flex-direction: column;
    text-align: center;
  }
`;

export const FormatImage = styled.p`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const LoadingWrapper = styled.div`
  margin-top: 130px;
  text-align: center;
`;

export const ResponseWrapper = styled.div`
  margin-top: 70px;
  width: 100%;
  text-align: center;

  a {
    background-color: ${Colors.goldDefault};
    padding: 10px 20px;
    height: 40px;
    display: inline-block;
    text-decoration: none;
    color: ${Colors.fontDefault};
    border-radius: 5px;

    &:hover {
      opacity: 0.8;
    }
  }

  button{
    margin-left:20px;
  }
`;

export const ResponseTitle = styled.p`
  font-size: 20px;
  text-transform: uppercase;
  color: ${Colors.fontDefault};

  &:after {
    content: '';
    margin: 5px auto 0;
    display: block;
    width: 400px;
    height: 4px;
    background-color: ${(props) =>
      props.error ? Colors.error : Colors.success};
  }
`;

export const ResponseP = styled.div`
  margin-top: 100px;
  width: 100%;
  text-align: center;

  p {
    line-height: 36px;
  }
`;


export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`