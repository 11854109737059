import styled from 'styled-components';

export const LicenceBar = styled.div`
  width: 100%;
  padding: 40px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  > div + div {
    margin-left: 20px;
  }

  @media (max-width: 900px) {
    padding: 10px;

    > div + div {
      margin-left: 0;
    }
  }

  @media (max-width: 400px) {
    padding: 5px;
  }
`;
