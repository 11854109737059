import styled from 'styled-components';

export const Photo = styled.div`
  width: 100%;
`;

export const Wrapper = styled.div`
  margin-top: 70px;
  z-index: 0;
  position: relative;
`;
