import styled from "styled-components";
import Colors from "../../../styles/Colors";

export const ButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;

    button{
    font-size: 12px;
    }

`; 

export const LoadedAllDataWarning = styled.p`

    font-size: 14px;
    display: block;
    padding-bottom: 5px;
    width: 60%;
    text-align: center;
    border-bottom: 1px solid ${Colors.blueDefault};
`;