import styled from 'styled-components';

export const LoadingWrapper = styled.div`
  text-align: center;
  margin-top: 70px;
`;

export const Wrapper = styled.div`
  margin-top: 70px;
  text-align: center;
  margin-bottom: 70px;

  p {
    line-height: 30px;

    @media (max-width: 400px) {
      font-size: 14px;
    }
  }

  @media (max-width: 550px) {
    margin-top: 70px;
    margin-bottom: 0;
  }
`;
