import styled from "styled-components";

export const NotificationCardWrapper = styled.div`
    display: flex;
    padding: 20px;
    ${(props) => (props.page === true ? 'border: 0;' : 'border: 1px solid white;')};
    position: relative;

    img{
        max-width: 50px;
    }


    div{
        display: flex;
        flex-direction: column;
        justify-content: center;
        // align-items: center;
        height: 60px;
    }


    p{
        font-size: 13px;
        text-transform: initial;
        text-align: left;
        margin-left: 20px;
    }

    p+p{
        margin-top: 10px;
        font-style: italic;
    }
`

export const Username = styled.span`
    font-weight: bold;
`

export const NotificationDate = styled.div`
    position: absolute;
    right: 30px;
    bottom: 0;
    font-size: ${(props) => (props.page ? '14px' : '12px')};


    @media (max-width: 750px){
        right: 10px;
        bottom: -15px;
    }
`