import styled from 'styled-components';

const Title = 'Merriweather, serif';
const Inherit = 'Montserrat, sans-serif ';

export const UserName = styled.p`
  font-size: ${(props) => props.userSize}px;
  text-transform: uppercase;
  font-weight: ${(props) => (props.title ? '500' : 'bold')};
  font-family: ${(props) => (props.title ? Title : Inherit)};
`;
